import { useQuery, useReactiveVar } from "@apollo/client";
import {
  GetDivisionsQuery,
  GetDivisionsQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
} from "../../graphql/graphqlTypes";
import { GET_DIVISIONS, GET_USER } from "../../graphql/queries";
import { activeInfo, setActive, showPopupVar } from "../../cache";
import { IoMdAddCircle as IconAdd } from "react-icons/io";
import IconButton from "../button/IconButton";
import IconHierarkeList from "../iconHierarkeList/IconHierarkiList";
import Spinner from "../Spinner";
import AddDivisionPopup from "../popups/AddDivisionPopup";
import { useHistory } from "react-router-dom";
import Button from "../button/Button";

function Sidebar() {
  let history = useHistory();
  const active = useReactiveVar(activeInfo);
  const {
    data: divisionData,
    loading: divisionLoading,
    refetch: divisionRefetch,
  } = useQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(GET_DIVISIONS);
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  return (
    <div className=" bg-white py-4 w-64 flex-shrink-0 flex flex-col justify-between items-stretch">
      {!divisionLoading ? (
        <>
          <div className="px-2 overflow-x-auto">
            <IconHierarkeList
              active={active?.division}
              list={{ name: "Accounts", divisions: divisionData?.divisions, id: "" }}
              step={0}
              onClick={(divison, account) => {
                // Prevent viewing "Accounts" division
                if (divison.id) {
                  activeInfo({ division: divison, account: account });
                  setActive({ division: divison, account: account });
                }
              }}
              accountParent={null}
            />
          </div>
          {history.location.pathname === "/admin" && (
            <div className="flex flex-col bg-white mt-2 px-2 mb-3">
              {userData?.user?.permissions?.includes("CreateAccount") && (
                <Button
                  label={"Add account"}
                  disable={false}
                  onClick={() => {
                    showPopupVar({
                      chiled: <AddDivisionPopup type={"ACCOUNT"} refetch={divisionRefetch} />,
                    });
                  }}
                />
                // <IconButton
                //   label={"Add account"}
                //   onClick={() => {
                //     showPopupVar({
                //       chiled: <AddDivisionPopup type={"ACCOUNT"} refetch={divisionRefetch} />,
                //     });
                //   }}
                //   Icon={IconAdd}
                // />
              )}
              {userData?.user?.permissions?.includes("CreateDivision") && (
                <Button
                  label={"Add group"}
                  disable={!active?.division || active.division.id === ""}
                  onClick={() => {
                    if (active?.division && active.division !== "") {
                      showPopupVar({
                        chiled: <AddDivisionPopup type={"GROUP"} refetch={divisionRefetch} />,
                      });
                    }
                  }}
                />
                //  <IconButton
                //     label={"Add group"}
                //     disabled={!active?.division || active.division.id === ""}
                //     onClick={() => {
                //       if (active?.division && active.division !== "") {
                //         showPopupVar({
                //           chiled: <AddDivisionPopup type={"GROUP"} refetch={divisionRefetch} />,
                //         });
                //       }
                //     }}
                //     Icon={IconAdd}
                //   />
              )}
            </div>
          )}
        </>
      ) : (
        <div className="mt-6 flex justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default Sidebar;
