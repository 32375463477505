import { useEffect, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  AllFirmwareQuery,
  AllFirmwareQueryVariables,
  CreateFirmwareRolloutMutation,
  CreateFirmwareRolloutMutationVariables,
  Division,
  Firmware,
  GetDivisionsQuery,
  GetDivisionsQueryVariables,
} from "../../graphql/graphqlTypes";
import { CREATE_FIRMWARE_ROLLOUT } from "../../graphql/mutations";
import { showPopupVar } from "../../cache";
import Button from "../button/Button";
import SelectInput from "../inputs/SelectInput";
import { GET_ALL_FIRMWARE, GET_ALL_FIRMWARE_ROLLOUTS, GET_DIVISIONS } from "../../graphql/queries";
import CheckboxHierarkiList from "../checkboxHierarkeList/CheckboxHierarkiList";
import { findChildeDivision } from "../../utils";

function FirmwareRolloutPopup() {
  const showPopup = useReactiveVar(showPopupVar);
  const { data: divisionData } = useQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(GET_DIVISIONS);
  const { data: allFirmware } = useQuery<AllFirmwareQuery, AllFirmwareQueryVariables>(GET_ALL_FIRMWARE);

  const [update, setUpdate] = useState(false);
  const [selectedFirmware, setSelectedFirmware] = useState<Firmware>(null);
  const [selectedGroup, setSelectedGroup] = useState<Division>(null);

  const [createFirmwareRollout] = useMutation<CreateFirmwareRolloutMutation, CreateFirmwareRolloutMutationVariables>(
    CREATE_FIRMWARE_ROLLOUT,
    {
      refetchQueries: [GET_ALL_FIRMWARE_ROLLOUTS],
    },
  );

  async function handelSave() {
    if (selectedFirmware) {
      await createFirmwareRollout({
        variables: { firmwareUnitId: selectedFirmware.id, divisionId: selectedGroup.id },
      });
    }
    showPopupVar(null);
  }

  useEffect(() => {
    showPopupVar({ ...showPopup, save: handelSave });
  }, []);
  return (
    <div className="flex flex-col justify-between px-2" style={{ width: "450px" }}>
      <div className="text-lg font-bold mt-2 mb-2 ">New Firmware Rollout</div>
      <div className=" items-start block mb-2 text-sm font-bold text-gray-900 ">Firmware</div>
      <SelectInput
        multiple={false}
        items={allFirmware?.allFirmware}
        onChange={(e: [Firmware]) => {
          setSelectedFirmware(e[0]);
          setUpdate(!update);
          showPopupVar({ ...showPopup, outsideSafety: true });
        }}
      />
      <div className="my-6">
        <div className=" items-start block mb-2 text-sm font-bold text-gray-900 ">Groups</div>
        <div className=" rounded-lg overflow-x-auto h-40  ">
          {divisionData?.divisions?.map((division, i) => {
            return (
              <CheckboxHierarkiList
                key={(division.id, i)}
                activeDivisions={findChildeDivision(selectedGroup).map((e) => {
                  return e.id;
                })}
                list={division}
                step={0}
                onClick={(div: Division) => {
                  if (div.id !== selectedGroup?.id) setSelectedGroup(div);
                  else setSelectedGroup(null);
                  showPopupVar({ ...showPopup, outsideSafety: true });
                }}
              />
            );
          })}
        </div>
      </div>
      {/* TODO add other parameters */}
      {/* <div className="mb-6">
        <div className=" items-start block mb-2 text-sm font-bold text-gray-900 ">Devices</div>
        <SelectInput
          multiple={true}
          items={firmwares.allFirmware}
          onChange={(e: Firmware) => {
            setSelectedFirmware(e[0].firmware);
            setUpdate(!update);
          }}
        />
      </div> */}
      {/* <Toggle id={"limitedRollout"} label={"Limited rollout"} initValue={false} onChange={undefined} /> */}
      <div className="-ml-4">
        <Button
          label={"Rollout"}
          disable={!(selectedFirmware && selectedGroup)}
          onClick={() => {
            handelSave();
          }}
        />
      </div>
    </div>
  );
}

export default FirmwareRolloutPopup;
