import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  AllFirmwareQuery,
  AllFirmwareQueryVariables,
  DeleteFirmwareMutation,
  DeleteFirmwareMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
} from "../../graphql/graphqlTypes";
import { GET_ALL_FIRMWARE, GET_USER } from "../../graphql/queries";
import { MdDelete as IconDelete } from "react-icons/md";
import { MdCloudDownload as IconDownload } from "react-icons/md";
import Spinner from "../Spinner";
import Tag from "../Tag";
import { firmwareFilterVar, firmwareSortVar, showConfirmDialogVar, showPopupVar } from "../../cache";
import { DELETE_FIRMWARE } from "../../graphql/mutations";
import { useState } from "react";
import Tooltip from "../inputs/Tooltip";
import { MdEdit as IconEdit } from "react-icons/md";
import FirmwarePopup from "../popups/FirmwarePopup";

function TableRowFirmware() {
  const [top, setTop] = useState(0);
  const firmwareFilter = useReactiveVar(firmwareFilterVar);
  const firmwareSort = useReactiveVar(firmwareSortVar);
  const {
    data: allFirmware,
    loading: allFirmwareLoading,
    refetch: allFirmwareRefetch,
  } = useQuery<AllFirmwareQuery, AllFirmwareQueryVariables>(GET_ALL_FIRMWARE, {
    variables: {
      types: Object.keys(firmwareFilter).filter((key) => {
        return firmwareFilter[key];
      }),
      sort: firmwareSort.sort,
      order: firmwareSort.order,
    },
  });
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  const [deleteFirmware] = useMutation<DeleteFirmwareMutation, DeleteFirmwareMutationVariables>(DELETE_FIRMWARE);
  return (
    <>
      {allFirmware?.allFirmware && allFirmware.allFirmware.length > 0 ? (
        allFirmware.allFirmware.map((firmware, i) => {
          return (
            <tr key={i} className="bg-white  text-gray-900 whitespace-nowrap">
              <th scope="row" className="px-6 py-4">
                <div className="flex flex-row items-center " onClick={() => {}}>
                  {firmware?.name}
                </div>
              </th>
              <td
                onMouseEnter={(e) => {
                  setTop(e.clientY);
                }}
                className="px-6 py-4 max-w-xs whitespace-nowrap overflow-hidden text-ellipsis group"
                title={firmware?.description}>
                {firmware?.description}
              </td>
              <td className="px-6 py-4 flex justify-left flex-grow flex-wrap">
                {firmware?.firmware?.type && (
                  <Tag
                    label={firmware.firmware.type}
                    type={
                      firmware.firmware.type === "critical"
                        ? "Red"
                        : firmware.firmware.type === "important"
                          ? "Yellow"
                          : "Gray"
                    }></Tag>
                )}
              </td>
              <td className="px-6 py-4 ">
                {firmware?.uploadedAt
                  ? new Date(firmware.uploadedAt).toLocaleString("sv-SE", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })
                  : ""}
              </td>
              <td>
                <div className="flex flex-row flex-nowrap justify-end items-center">
                  {userData?.user?.permissions.includes("ViewFirmware") && (
                    <div
                      className="pl-6 py-4 flex flex-row"
                      onClick={() => {
                        if (firmware?.firmware?.data) {
                          let aTag = document.createElement("a");
                          aTag.href = "data:application/octet-stream;base64," + firmware.firmware.data;
                          aTag.download = firmware.name + ".zip";
                          aTag.click();
                        }
                        allFirmwareRefetch();
                      }}>
                      <IconDownload className="h-5 w-5 mr-2 cursor-pointer" title="Download firmware" />
                    </div>
                  )}

                  {userData?.user?.permissions.includes("EditFirmware") && (
                    <div
                      className="py-4 flex flex-row"
                      onClick={() => {
                        showPopupVar({
                          chiled: <FirmwarePopup refetch={allFirmwareRefetch} firmware={firmware} />,
                        });
                      }}>
                      <IconEdit className="h-5 w-5 cursor-pointer" title="Edit firmware" />
                    </div>
                  )}
                  {userData?.user?.permissions.includes("DeleteFirmware") && (
                    <div
                      className="pr-6 py-4 flex flex-row"
                      onClick={() => {
                        showConfirmDialogVar({
                          title: "Delete firmware rollout",
                          message: "Are you sure?",
                          confirmText: "Delete",
                          onConfirm: async () => {
                            showConfirmDialogVar(null);
                            await deleteFirmware({
                              variables: {
                                firmwareId: firmware.firmwareId,
                              },
                            })
                              .then((e) => {
                                if (e?.data?.deleteFirmware?.error) {
                                  showConfirmDialogVar({
                                    title: e.data.deleteFirmware.error.msg,
                                    message: "Delete all firmware rollout before deleting the firmware",
                                    confirmText: "Ok",
                                    onConfirm: async () => {
                                      showConfirmDialogVar(null);
                                    },
                                  });
                                }
                              })
                              .catch(() => {
                                console.log("error");
                              });

                            allFirmwareRefetch();
                          },
                          onCancel: () => {
                            showConfirmDialogVar(null);
                          },
                        });
                      }}>
                      <IconDelete className="h-5 w-5 mr-2 cursor-pointer" title="Remove firmware" />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={6}>
            <div className="py-4 px-6 whitespace-nowrap"> No Firmware found </div>
          </td>
        </tr>
      )}
      {allFirmwareLoading && (
        <tr>
          <td colSpan={6}>
            <div className="m-6 flex justify-center">
              <Spinner />
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default TableRowFirmware;
