import React from "react";
import ReactDOM from "react-dom";
import { setActive, showConfirmDialogVar, showPopupVar } from "./cache";
import { createBrowserHistory } from "history";
import { setContext } from "@apollo/client/link/context";
import { ApolloClient, ApolloProvider, InMemoryCache, useReactiveVar } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Route, Router, Switch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { createUploadLink } from "apollo-upload-client";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Register from "./pages/Register";
import Devices from "./pages/Devices";
import Firmware from "./pages/Firmware";
import Diagnostic from "./pages/Diagnostic";

import AlertPopup from "./components/popups/AlertPopup";
import Popup from "./components/popups/Popup";

import "./index.css";
const hist = createBrowserHistory();

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("jwt-bubl-cloud");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "Accept-Encoding": "gzip",
    },
  };
});

const httpLink = createUploadLink({
  uri: "/graphql",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (extensions && extensions.code === "UNAUTHENTICATED") {
        console.log("UNAUTHENTICATED");
        setActive(null);
        hist.push("/login");
      } else if (extensions && extensions.code === "FORBIDDEN") {
        console.log("FORBIDDEN");
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpAuthLink = authLink.concat(httpLink);
const httpAuthErrorLink = errorLink.concat(httpAuthLink);

const client = new ApolloClient({
  link: httpAuthErrorLink,
  cache: new InMemoryCache({}),
  connectToDevTools: true,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router history={hist}>
        <RouteSwitch />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
function RouteSwitch() {
  const showConfirmDialog = useReactiveVar(showConfirmDialogVar);
  const showPopup = useReactiveVar(showPopupVar);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <AnimatePresence exitBeforeEnter={true}>
          {showConfirmDialog && <AlertPopup {...showConfirmDialog} />}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter={true}>{showPopup && <Popup {...showPopup} />}</AnimatePresence>
        <AnimatePresence exitBeforeEnter={true}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/admin" component={Admin} />
            <Route path="/register" component={Register} />
            <Route path="/iot/devices" component={Devices} />
            <Route path="/iot/firmware" component={Firmware} />
            <Route path="/iot/diagnostic" component={Diagnostic} />
            <Route path="/" component={Home} />
          </Switch>
        </AnimatePresence>
      </DndProvider>
    </>
  );
}
