import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { showPopupVar } from "../../cache";
import {
  Account,
  Device,
  Division,
  GetDivisionsQuery,
  GetDivisionsQueryVariables,
  MoveDeviceMutation,
  MoveDeviceMutationVariables,
} from "../../graphql/graphqlTypes";
import { MOVE_DEVICE } from "../../graphql/mutations";
import { GET_COUNT_DEVICES, GET_DEVICES_BY_DIVISION, GET_DIVISIONS } from "../../graphql/queries";
import Button from "../button/Button";
import CheckboxHierarkiList from "../checkboxHierarkeList/CheckboxHierarkiList";

type PropsPopup = {
  device: Device;
};
function MoveDevicePopup({ device }: PropsPopup) {
  const showPopup = useReactiveVar(showPopupVar);
  const { data: divisionData } = useQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(GET_DIVISIONS);

  const [selectedGroup, setSelectedGroup] = useState<Division>(null);
  const [activeAccount, setActiveAccount] = useState<Account>(null);

  const [moveDevice] = useMutation<MoveDeviceMutation, MoveDeviceMutationVariables>(MOVE_DEVICE, {
    refetchQueries: [GET_DEVICES_BY_DIVISION, GET_COUNT_DEVICES],
  });

  async function handelSave() {
    if (selectedGroup && device && activeAccount) {
      await moveDevice({
        variables: {
          deviceId: device.id ? device.id : device.macAddress,
          divisionId: selectedGroup.id,
          accountId: activeAccount.id,
        },
      }).catch((e) => {
        console.log(e);
      });
    }
    showPopupVar(null);
  }

  useEffect(() => {
    showPopupVar({ ...showPopup, save: handelSave });
  }, []);
  if (!device) showPopupVar(null);
  return (
    <div className="flex flex-col justify-between px-2" style={{ width: "450px" }}>
      <div className="text-lg font-bold mt-2 mb-2 ">Move Device </div>
      <div className=" items-start block mb-2 text-sm font-bold text-gray-900 ">{device.macAddress} </div>

      <div className="my-6">
        <div className=" items-start block mb-2 text-sm font-bold text-gray-900 ">Choose a Group</div>
        <div className=" rounded-lg overflow-x-auto h-40  ">
          {divisionData?.divisions?.map((division, i) => {
            return (
              <CheckboxHierarkiList
                key={(division.id, i)}
                activeDivisions={[selectedGroup?.id]}
                list={division}
                step={0}
                account={division.account}
                onClick={(div: Division, account: Account) => {
                  setActiveAccount(account);
                  if (div.id !== selectedGroup?.id) setSelectedGroup(div);
                  else setSelectedGroup(null);
                  showPopupVar({ ...showPopup, outsideSafety: true });
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="-ml-4">
        <Button
          label={"Move"}
          disable={!(device && selectedGroup)}
          onClick={() => {
            handelSave();
          }}
        />
      </div>
    </div>
  );
}

export default MoveDevicePopup;
