import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetUserQuery,
  GetUserQueryVariables,
  LoginMutation,
  LoginMutationVariables,
  RecoverPasswordMutation,
  RecoverPasswordMutationVariables,
} from "../graphql/graphqlTypes";
import { LOGIN, RECOVER_PASSWORD } from "../graphql/mutations";
import { GET_USER } from "../graphql/queries";
import TextInput from "../components/inputs/TextInput";
import { ReactComponent as BublLogo } from "../assets/svg/bubl_logo.svg";
import BublPulse from "../assets/images/bubl_pulse.png";
import Button from "../components/button/Button";

function Login() {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [emailComplete, setEmailComplete] = useState(false);
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsloggingIn] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [recoverEmailSent, setRecoverEmailSent] = useState(false);

  const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN);
  const [recover] = useMutation<RecoverPasswordMutation, RecoverPasswordMutationVariables>(RECOVER_PASSWORD);
  const { data: userData, loading: userDataLoading } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!userDataLoading && userData) {
      // Already logged in: redirect to builder
      history.push("/");
    }
  }, [userDataLoading]);

  useEffect(() => {
    if (email && email.match(/^\S+@\S+\.\S+$/)) {
      setEmailComplete(true);
    } else {
      setEmailComplete(false);
    }
  }, [email]);

  async function handleLoginUser() {
    if (emailComplete && password) {
      setIsloggingIn(true);
      const { data } = await login({
        variables: { email: email, password: password },
      });
      if (data.login.token) {
        localStorage.setItem("jwt-bubl-cloud", data.login.token);
        setTimeout(() => {
          history.push("/");
        }, 1000);
      } else {
        setIsloggingIn(false);
        setResponseError("Wrong password");
        console.log("wrong password");
      }
    }
  }
  return (
    <div className="h-full w-full flex flex-col justify-center items-center bg-bubl-green relative">
      <div className="bg-white rounded-full z-50" style={{ height: 600, width: 600, padding: 140 }}>
        <div className="w-full flex justify-center">
          <BublLogo className="w-32" />
        </div>
        <div className="text-lg font-bold mt-8 mb-6">Sign In</div>
        <TextInput
          id={"email"}
          label="Your email"
          type="email"
          value={email}
          onChange={(value) => {
            setEmail(value);
            if (recoverEmailSent) setRecoverEmailSent(false);
            if (responseError) setResponseError("");
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleLoginUser();
            }
          }}
        />
        <TextInput
          id={"password"}
          label="Password"
          type="password"
          value={password}
          onChange={(value) => {
            setPassword(value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleLoginUser();
            }
          }}
        />
        {emailComplete && responseError ? (
          <div className="flex flex-col items-center -mt-2">
            {!recoverEmailSent && (
              <div
                className="text-xs cursor-pointer transform text-black transition-all duration-500 hover:scale-105"
                onClick={async () => {
                  const { data, errors } = await recover({
                    variables: { email: email },
                  });
                  setRecoverEmailSent(true);
                }}>
                Forgot your password?
              </div>
            )}
            {recoverEmailSent && <div className="text-xs text-black">{"Password recovery email sent"}</div>}
          </div>
        ) : (
          <div className="h-2"></div>
        )}
        <div className="flex justify-center mt-2">
          <Button
            label={"Log in"}
            disable={!(emailComplete && password)}
            onClick={(e) => {
              handleLoginUser();
            }}
          />
        </div>
      </div>
      <img
        className="absolute "
        style={{ width: 1200, height: 1100, bottom: -340, right: -200, transform: "rotate(10deg)", zIndex: 0 }}
        src={BublPulse}></img>
    </div>
  );
}

export default Login;
