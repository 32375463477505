import { motion } from "framer-motion";

type NavbarItemProps = {
  label: string;
  href?: string;
  active: boolean;
  implemented: boolean;
};
function NavbarItem(props: NavbarItemProps) {
  const { label, href, active, implemented } = props;
  let classValues = implemented
    ? "text-white px-4 text-sm hover:text-opacity-75 cursor-pointer"
    : "text-white px-4 text-sm text-opacity-60 cursor-default"; //hover:text-opacity-75 cursor-pointer "
  return (
    <div className="">
      <a {...(href && { href: href })} className={classValues} style={{ fontWeight: active ? "bolder" : "auto" }}>
        {label}
      </a>
      {active ? (
        <motion.div
          layoutId="border"
          className="border-white border-b-2 rounded m-auto"
          style={{ width: "calc(100% - 20px)" }}></motion.div>
      ) : (
        <div className="pt-1"></div>
      )}
    </div>
  );
}

export default NavbarItem;
