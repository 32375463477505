import React from "react";
import { IoIosArrowUp as IconBack } from "react-icons/io";
type Props = {
  headers: Array<String>;
  Row: JSX.Element;
  sort: Function;
  activeSort: string;
  order: boolean;
};
function Table(props: Props) {
  const { headers, Row, sort, activeSort, order } = props;
  return (
    <div className="static sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 border-b">
          <tr>
            {headers.map((label, i) => {
              return (
                <th
                  key={i}
                  scope="col"
                  className="px-6 py-3 flex-shrink-0"
                  onClick={() => {
                    if (label !== "") {
                      sort(label);
                    }
                  }}
                  style={{ cursor: label !== "" && label !== "Install" ? "pointer" : "auto" }}>
                  <div className="flex flex-row whitespace-nowrap">
                    {label}
                    {activeSort !== "" && activeSort === label && (
                      <IconBack
                        style={{ fill: "rgb(17 24 39)" }}
                        className={`h-4 w-4 transform transition-all ${order ? "" : "-rotate-180"} `}
                      />
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{Row}</tbody>
      </table>
    </div>
  );
}

export default Table;
