import React, { useEffect, useState } from "react";
import IconHierarkiListRow from "./IconnHierarkiListRow";
// Icons
import { BsFilePersonFill as Icon2 } from "react-icons/bs";
import { GoPerson as Icon1 } from "react-icons/go";
import { MdGpsFixed as Icon3 } from "react-icons/md";
import {
  Account,
  CountDevicesQuery,
  CountDevicesQueryVariables,
  Division,
  GetAllDivisionUserRolesQuery,
  GetAllDivisionUserRolesQueryVariables,
  GetDivisionUserRolesQuery,
  GetDivisionUserRolesQueryVariables,
  GetUnassignedQuery,
  GetUnassignedQueryVariables,
} from "../../graphql/graphqlTypes";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  GET_ALL_DIVISION_USER_ROLES,
  GET_COUNT_DEVICES,
  GET_DIVISION_USER_ROLES,
  GET_UNASSIGNED,
} from "../../graphql/queries";
import { adminFilterVar, deviceFilterVar, userRolesFilterVar } from "../../cache";
import { useHistory } from "react-router-dom";
type Props = {
  list: Division;
  active: Division;
  step: number;
  accountParent: Account;
  onClick: Function;
};
// Recursive list
function IconHierarkeList(props: Props) {
  let history = useHistory();
  const { list, active, step, onClick, accountParent } = props;
  const icons = [Icon1, Icon2, Icon3];
  const [open, setOpen] = useState(true);
  const [account, setAccount] = useState(accountParent ? accountParent : null);
  const deviceFilter = useReactiveVar(deviceFilterVar);
  const adminFilter = useReactiveVar(adminFilterVar);
  const userRolesFilter = useReactiveVar(userRolesFilterVar);
  const { data: divisionUserRolesData } = useQuery<GetDivisionUserRolesQuery, GetDivisionUserRolesQueryVariables>(
    GET_DIVISION_USER_ROLES,
    {
      variables: {
        divisionId: list?.id,
        roles: adminFilter.roles,
        sort: userRolesFilter.sort,
        order: userRolesFilter.order,
      },
      fetchPolicy: "no-cache",
      skip: !list?.id || history.location.pathname !== "/admin",
    },
  );
  const { data: userAccountRoles } = useQuery<GetAllDivisionUserRolesQuery, GetAllDivisionUserRolesQueryVariables>(
    GET_ALL_DIVISION_USER_ROLES,
    {
      fetchPolicy: "no-cache",
      variables: {
        accountId: list?.account?.id,
        roles: adminFilter.roles,
        sort: adminFilter.sort,
        order: adminFilter.order,
      },
      skip: !list?.id || history.location.pathname !== "/admin" || step > 1,
    },
  );
  const { data: countDeviceDivision } = useQuery<CountDevicesQuery, CountDevicesQueryVariables>(GET_COUNT_DEVICES, {
    // Why would it not be cached?? Removed until proven otherwise
    // fetchPolicy: "no-cache",
    variables: {
      divisionId: list?.id,
      // Below parameters were unused, causing a bunch of queries
      // searchText: deviceFilter.searchText,
      // sort: deviceFilter.sort,
      // order: deviceFilter.order,
    },
    skip: !list?.id || history.location.pathname !== "/iot/devices",
  });
  const { data: allUnassignedDevices } = useQuery<GetUnassignedQuery, GetUnassignedQueryVariables>(GET_UNASSIGNED, {
    variables: { searchText: deviceFilter.searchText, sort: deviceFilter.sort, order: deviceFilter.order },
    skip: !(list?.name === "Unassigned" && account?.name === "bubl"),
  });
  function getAmount() {
    if (history.location.pathname === "/admin") {
      if (step >= 2) return divisionUserRolesData?.userDivisionUserRoles.length;
      else return userAccountRoles?.allDivisionUserRoles?.length;
    } else if (history.location.pathname === "/iot/devices" && allUnassignedDevices?.getUnassigned?.length) {
      return countDeviceDivision?.countDevices + allUnassignedDevices?.getUnassigned?.length;
    } else {
      return countDeviceDivision?.countDevices;
    }
  }
  useEffect(() => {
    if (list.account) {
      setAccount(list.account);
    }
  }, [list]);
  useEffect(() => {
    if (accountParent) {
      setAccount(accountParent);
    }
  }, [accountParent]);
  return (
    <div className={" mt-0.5 ml-2"}>
      {/* Show the row */}
      <IconHierarkiListRow
        label={list.name}
        Icon={step > 1 ? icons[2] : icons[step]}
        amount={getAmount()}
        step={step}
        active={active?.id === list.id}
        open={open}
        setOpen={setOpen}
        accountId={account?.id}
        divisionId={list?.id}
        hasChildren={list.divisions && list.divisions.length > 0}
        onClick={() => {
          if (!(open && active?.id !== list.id) || !list.id) setOpen(!open);
          onClick(list, account);
        }}
      />
      {/* Go down in hirerkin to the division children */}
      {list?.divisions &&
        list.divisions.length > 0 &&
        open &&
        list.divisions.map((element, i) => {
          return (
            <IconHierarkeList
              active={active}
              key={i}
              list={element}
              step={step + 1}
              onClick={onClick}
              accountParent={account}
            />
          );
        })}
    </div>
  );
}

export default IconHierarkeList;
