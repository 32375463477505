import gql from "graphql-tag";
// ** ACCOUNT **
export const GET_ACCOUNT = gql`
  query getAccount($accountId: String!) {
    account(accountId: $accountId) {
      id
      name
      disabled
    }
  }
`;
// ** USER **
export const GET_REGISTER_USER = gql`
  query getRegisterUser($registerToken: String) {
    user(registerToken: $registerToken) {
      id
      name
      email
      permissions
    }
  }
`;
export const GET_USER = gql`
  query getUser {
    user {
      id
      name
      email
      permissions
    }
  }
`;

export const GET_DIVISION_USER_ROLES = gql`
  query getDivisionUserRoles($divisionId: String!, $roles: [String!], $sort: String, $order: Boolean) {
    userDivisionUserRoles(divisionId: $divisionId, roles: $roles, sort: $sort, order: $order) {
      name
      id
      roles {
        name
        id
        divisions {
          id
          name
        }
      }
    }
  }
`;
export const GET_ALL_DIVISION_USER_ROLES = gql`
  query getAllDivisionUserRoles($accountId: String!, $roles: [String!], $sort: String, $order: Boolean) {
    allDivisionUserRoles(accountId: $accountId, roles: $roles, sort: $sort, order: $order) {
      id
      name
      email
      roles {
        name
        id
        divisions {
          id
          name
          divisions {
            id
            name
            account {
              id
              name
            }
            divisions {
              id
              name
              account {
                id
                name
              }
              divisions {
                id
                name
                account {
                  id
                  name
                }
                divisions {
                  id
                  name
                  account {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      disabled
      lastLoggedIn
    }
  }
`;
export const GET_ACCOUNT_USER = gql`
  query accountUsers($accountId: String!) {
    accountUsers(accountId: $accountId) {
      id
      name
      email
      roles {
        name
        id
        divisions {
          id
          name
        }
      }
      disabled
      lastLoggedIn
    }
  }
`;
export const COUNT_DIVISION_USER_ROLES = gql`
  query countDivisionUserRoles($divisionId: String!, $roles: [String!], $sort: String, $order: Boolean) {
    countDivisionUserRoles(divisionId: $divisionId, roles: $roles, sort: $sort, order: $order)
  }
`;

//  ** DIVISION **
//Limitet to 5 subdivision add to get more divissions
export const GET_DIVISIONS = gql`
  query getDivisions {
    divisions {
      id
      name
      account {
        id
        name
      }
      disabled
      divisions {
        id
        name
        account {
          id
          name
        }
        disabled
        divisions {
          id
          name
          account {
            id
            name
          }
          disabled
          divisions {
            id
            name
            account {
              id
              name
            }
            disabled
            divisions {
              id
              name
              account {
                id
                name
              }
              disabled
              divisions {
                id
                name
                account {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

//Limitet to 5 subdivision add to get more divissions
export const GET_DIVISIONS_BY_ACCOUNT = gql`
  query getDivisionsbyAccount($accountId: String!) {
    getDivisionsbyAccount(accountId: $accountId) {
      id
      name
      account {
        id
        name
      }
      divisions {
        id
        name
        account {
          id
          name
        }
        divisions {
          id
          name
          account {
            id
            name
          }
          divisions {
            id
            name
            account {
              id
              name
            }
            divisions {
              id
              name
              account {
                id
                name
              }
              divisions {
                id
                name
                account {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

//  ** ROLES & PERMISSIONS **
export const GET_ROLES = gql`
  query getRoles {
    roles {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;
export const GET_PREMISSIONS = gql`
  query getPermissions {
    permissions {
      id
      name
    }
  }
`;

//  ** DEVICE **
export const GET_UNASSIGNED = gql`
  query getUnassigned($searchText: String, $sort: String, $order: Boolean) {
    getUnassigned(searchText: $searchText, sort: $sort, order: $order) {
      id
      macAddress
      serialNumber
      firmware
      hardware
      hasWarning
      lastActivity
    }
  }
`;

export const GET_DEVICES_BY_DIVISION = gql`
  query devicesByDivision($divisionId: String!, $searchText: String, $sort: String, $order: Boolean) {
    devicesByDivision(divisionId: $divisionId, searchText: $searchText, sort: $sort, order: $order) {
      id
      macAddress
      serialNumber
      firmware
      hardware
      hasWarning
      divisionId
      lastActivity
    }
  }
`;
export const GET_COUNT_DEVICES = gql`
  query countDevices($divisionId: String!) {
    countDevices(divisionId: $divisionId)
  }
`;
// ** FIRMWARE **
export const GET_ALL_FIRMWARE = gql`
  query allFirmware($types: [String!], $sort: String, $order: Boolean) {
    allFirmware(types: $types, sort: $sort, order: $order) {
      id
      name
      description
      firmwareId
      firmware {
        type
        data
      }
      uploadedAt
    }
  }
`;
export const GET_FIRMWARE_TYPES = gql`
  query allFirmwareTypes {
    allFirmwareTypes
  }
`;
// ** FIRMWARE ROLLOUTS **
export const GET_ALL_FIRMWARE_ROLLOUTS = gql`
  query allFirmwareRollouts($status: [String!], $sort: String, $order: Boolean) {
    allFirmwareRollouts(status: $status, sort: $sort, order: $order) {
      id
      firmwareUnit {
        id
        name
        description
        firmwareId
      }
      status
      install
      rolledOutAt
      division {
        parentDivision {
          id
          name
          account {
            id
            name
          }
          parentDivision {
            id
            name
            account {
              id
              name
            }
            parentDivision {
              id
              name
              account {
                id
                name
              }
              parentDivision {
                id
                name
                account {
                  id
                  name
                }
                parentDivision {
                  id
                  name
                  account {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        id
        name
        account {
          id
          name
        }
        divisions {
          id
          name
          account {
            id
            name
          }
          divisions {
            id
            name
            account {
              id
              name
            }
            divisions {
              id
              name
              account {
                id
                name
              }
              divisions {
                id
                name
                account {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_ACTIVE_FIRMWARE = gql`
  query getActiveFirmware($divisionId: String!) {
    getActiveFirmware(divisionId: $divisionId) {
      id
      name
      description
      firmwareId
      uploadedAt
    }
  }
`;
// **  DIAGNOSTIC **
export const GET_ALL_DIAGNOSTIC = gql`
  query allDiagnostics($types: [String!], $searchText: String, $sort: String, $order: Boolean, $take: Int, $skip: Int) {
    allDiagnostics(types: $types, searchText: $searchText, sort: $sort, order: $order, take: $take, skip: $skip) {
      macAddress
      initId
      message
      type
      date
      messageType
      installRelationId
    }
  }
`;
export const GET_COUNT_ALL_DIAGNOSTIC = gql`
  query countAllDiagnostics($types: [String!], $searchText: String) {
    countAllDiagnostics(types: $types, searchText: $searchText)
  }
`;
