import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { IoIosArrowUp as IconArrow, IoIosWarning as IconWarning } from "react-icons/io";
import { MdInfo as IconInfo, MdDriveFileMoveOutline as IconMove } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { activeInfo, deviceFilterVar, diagnosticFilterVar, showConfirmDialogVar, showPopupVar } from "../../cache";
import {
  Device,
  DevicesByDivisionQuery,
  DevicesByDivisionQueryVariables,
  Division,
  GetActiveFirmwareQuery,
  GetActiveFirmwareQueryVariables,
  GetUnassignedQuery,
  GetUnassignedQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  MoveDeviceMutation,
  MoveDeviceMutationVariables,
} from "../../graphql/graphqlTypes";
import { MOVE_DEVICE } from "../../graphql/mutations";
import {
  GET_ACTIVE_FIRMWARE,
  GET_COUNT_DEVICES,
  GET_DEVICES_BY_DIVISION,
  GET_UNASSIGNED,
  GET_USER,
} from "../../graphql/queries";
import Spinner from "../Spinner";
import Tag from "../Tag";
import MoveDevicePopup from "../popups/MoveDevicePopup";
export const ItemTypes = {
  ROW: "row",
};
function TableRowDevices() {
  const active = useReactiveVar(activeInfo);
  const deviceFilter = useReactiveVar(deviceFilterVar);
  const {
    data: allDevices,
    loading: allDevicesLoading,
    refetch: allDevicesRefetch,
  } = useQuery<DevicesByDivisionQuery, DevicesByDivisionQueryVariables>(GET_DEVICES_BY_DIVISION, {
    variables: {
      divisionId: active?.division?.id,
      searchText: deviceFilter.searchText,
      sort: deviceFilter.sort,
      order: deviceFilter.order,
    },
    skip: !active?.division?.id,
    // fetchPolicy: "network-only",
  });
  const {
    data: allUnassignedDevices,
    loading: allUnassignedDevicesLoading,
    refetch: allUnassignedDevicesRefetch,
  } = useQuery<GetUnassignedQuery, GetUnassignedQueryVariables>(GET_UNASSIGNED, {
    variables: { searchText: deviceFilter.searchText, sort: deviceFilter.sort, order: deviceFilter.order },
    skip: !(active?.division?.name === "Unassigned" && active?.account?.name === "bubl"),
  });
  const { data: FirmwareData } = useQuery<GetActiveFirmwareQuery, GetActiveFirmwareQueryVariables>(
    GET_ACTIVE_FIRMWARE,
    {
      variables: {
        divisionId: active?.division?.id,
      },
      skip: !active?.division?.id,
    },
  );
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  function updateRefetch() {
    allDevicesRefetch();
    allUnassignedDevicesRefetch();
    deviceFilterVar({ ...deviceFilter, searchText: "Update" });
    deviceFilterVar({ ...deviceFilter, searchText: "" });
  }
  return (
    <>
      {allUnassignedDevices?.getUnassigned &&
        userData?.user?.permissions.includes("EditDevice") &&
        allUnassignedDevices.getUnassigned.length > 0 &&
        allUnassignedDevices.getUnassigned.map((device, i) => {
          return (
            <RowWithDrag
              key={i}
              device={device}
              userData={userData}
              divisionData={active.division}
              refetch={updateRefetch}
              updated={
                FirmwareData?.getActiveFirmware?.name &&
                device.firmware &&
                FirmwareData.getActiveFirmware.name !== device.firmware
              }
            />
          );
        })}
      {allDevices?.devicesByDivision?.map((device, i) => {
        return (
          <RowWithDrag
            key={i}
            device={device}
            userData={userData}
            divisionData={active.division}
            refetch={updateRefetch}
            updated={
              FirmwareData?.getActiveFirmware?.name &&
              device.firmware &&
              FirmwareData.getActiveFirmware.name !== device.firmware
            }
          />
        );
      })}
      {(active?.division?.name !== "Unassigned"
        ? !allDevices?.devicesByDivision || allDevices?.devicesByDivision?.length < 1
        : (!allDevices?.devicesByDivision || allDevices?.devicesByDivision?.length < 1) &&
          (!allUnassignedDevices?.getUnassigned || allUnassignedDevices.getUnassigned.length < 1)) && (
        <tr>
          <td colSpan={6}>
            <div className="py-4 px-6 whitespace-nowrap"> No devices found </div>
          </td>
        </tr>
      )}
      {(allDevicesLoading || allUnassignedDevicesLoading) && (
        <tr>
          <td colSpan={6}>
            <div className="m-6 flex justify-center">
              <Spinner />
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
interface DropResult {
  label: string;
  divisionId: string;
  accountId: string;
  step: number;
  updated: boolean;
}
type RowWithDragprops = {
  device: Device;
  userData: GetUserQuery;
  divisionData: Division;
  refetch: Function;
  updated: boolean;
};

function RowWithDrag({ device, userData, divisionData, refetch, updated }: RowWithDragprops) {
  let history = useHistory();
  const deviceFilter = useReactiveVar(deviceFilterVar);
  const diagnosticFilter = useReactiveVar(diagnosticFilterVar);

  const [moveDevice] = useMutation<MoveDeviceMutation, MoveDeviceMutationVariables>(MOVE_DEVICE, {
    refetchQueries: [GET_DEVICES_BY_DIVISION, GET_COUNT_DEVICES],
  });

  const [{ opacity, background }, drag] = useDrag(
    () => ({
      type: ItemTypes.ROW,
      item: device,
      end(item: Device, monitor) {
        const dropResult = monitor.getDropResult() as DropResult;
        if (item && dropResult) {
          showConfirmDialogVar({
            title: "Move device",
            message:
              "Move device " +
              device.macAddress +
              " to " +
              (dropResult.step < 2 ? "account " : "group ") +
              dropResult.label,
            confirmText: "Move",
            onConfirm: async () => {
              //Move Device
              await moveDevice({
                variables: {
                  deviceId: item.id ? item.id : item.macAddress,
                  divisionId: dropResult.divisionId,
                  accountId: dropResult.accountId,
                },
              }).catch((e) => {
                console.log(e);
              });
              showConfirmDialogVar(null);
            },
            onCancel: () => {
              showConfirmDialogVar(null);
            },
          });
        }
      },
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
        background: monitor.isDragging() ? "rgb(130 219 194)" : "rgb(255,255,255)",
      }),
    }),
    [device],
  );

  function getDivisionsPathFromActive(fromDivision: Division, toDivisionId: string): Division[] {
    if (fromDivision.id === toDivisionId) return [fromDivision];
    if (!fromDivision.divisions) return [];
    let c = [];
    fromDivision.divisions.forEach((division) => {
      const recusiveChilde = getDivisionsPathFromActive(division, toDivisionId);
      if (recusiveChilde.length > 0) {
        c = c.concat(recusiveChilde, fromDivision);
      }
    });
    return c;
  }
  return (
    <tr ref={drag} className=" text-gray-900 whitespace-nowrap" style={{ opacity: opacity, background: background }}>
      <th scope="row" className="px-6 py-4">
        <div className="flex flex-row items-center " onClick={() => {}}>
          {device.macAddress}
          {device?.hasWarning && (
            <IconWarning
              className="h-4 w-4 ml-2  cursor-pointer"
              onClick={() => {
                if (userData?.user?.permissions.includes("ViewDiagnostic")) {
                  diagnosticFilterVar({
                    ...diagnosticFilter,
                    searchText: device.macAddress,
                  });
                  history.push(`/iot/diagnostic`);
                  refetch();
                }
              }}
            />
          )}
        </div>
      </th>
      <td className="px-6 py-4">{device?.serialNumber}</td>
      <td className="px-6 py-4 ">
        <div className="flex flex-row  justify-start">
          {device?.firmware}
          {updated && (
            <IconArrow
              className="h-4 w-4 ml-2 cursor-pointer"
              onClick={() => {
                history.push(`/iot/firmware`);
              }}
            />
          )}
        </div>
      </td>
      <td className="px-6 py-4 justify-start">{device?.hardware}</td>
      {deviceFilter.searchText !== "" && (
        <td className="px-6 py-4 flex justify-left flex-grow flex-wrap">
          {getDivisionsPathFromActive(divisionData, device.divisionId)
            .reverse()
            .map((division, i) => {
              return <Tag key={i} label={division.name} type={"Gray"} />;
            })}
        </td>
      )}
      <td className="px-6 py-4 ">
        {device?.lastActivity ? new Date(device.lastActivity).toLocaleString("sv-SE", { dateStyle: "short" }) : ""}
      </td>
      <td>
        <div className="flex flex-row flex-nowrap justify-end items-center">
          {userData?.user?.permissions.includes("EditDevice") && (
            <div
              className="pl-6 py-4 flex flex-row"
              onClick={() => {
                showPopupVar({
                  chiled: <MoveDevicePopup device={device} />,
                });
              }}>
              <IconMove className="h-5 w-5 mr-2 cursor-pointer" title="Move device" />
            </div>
          )}
          {userData?.user?.permissions.includes("ViewDiagnostic") && (
            <div
              className="pr-6 pl-2 py-4 flex flex-row"
              onClick={() => {
                diagnosticFilterVar({
                  ...diagnosticFilter,
                  searchText: device.macAddress,
                });
                history.push(`/iot/diagnostic`);
                refetch();
              }}>
              <IconInfo className="h-5 w-5 mr-2 cursor-pointer" title="Diagnostic for device" />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}

export default TableRowDevices;
