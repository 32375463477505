import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { IoIosWarning as IconWarning } from "react-icons/io";
import { MdDelete as IconDelete, MdInfo as IconInfo } from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  activeInfo,
  deviceFilterVar,
  diagnosticFilterVar,
  firmwareFilterRolloutVar,
  firmwareRolloutSortVar,
  showConfirmDialogVar,
} from "../../cache";
import {
  AllFirmwareRolloutsQuery,
  AllFirmwareRolloutsQueryVariables,
  DeleteFirmwareRolloutMutation,
  DeleteFirmwareRolloutMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
} from "../../graphql/graphqlTypes";
import { DELETE_FIRMWARE_ROLLOUT } from "../../graphql/mutations";
import { GET_ALL_FIRMWARE_ROLLOUTS, GET_USER } from "../../graphql/queries";
import { findParentDivisions } from "../../utils";
import Spinner from "../Spinner";
import Tag from "../Tag";

function TableRowFirmreRollouts() {
  let history = useHistory();
  const firmwareFilterRollout = useReactiveVar(firmwareFilterRolloutVar);
  const firmwareRolloutSort = useReactiveVar(firmwareRolloutSortVar);
  const diagnosticFilter = useReactiveVar(diagnosticFilterVar);
  const deviceFilter = useReactiveVar(deviceFilterVar);

  const {
    data: allFirmwareRollouts,
    loading: allFirmwareRolloutsLoading,
    refetch: allFirmwareRolloutsRefetch,
  } = useQuery<AllFirmwareRolloutsQuery, AllFirmwareRolloutsQueryVariables>(GET_ALL_FIRMWARE_ROLLOUTS, {
    variables: {
      status: Object.keys(firmwareFilterRollout).filter((key) => {
        return firmwareFilterRollout[key];
      }),
      sort: firmwareRolloutSort.sort,
      order: firmwareRolloutSort.order,
    },
  });
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  const [deleteFirmwareRollout] = useMutation<DeleteFirmwareRolloutMutation, DeleteFirmwareRolloutMutationVariables>(
    DELETE_FIRMWARE_ROLLOUT,
  );

  return (
    <>
      {allFirmwareRollouts?.allFirmwareRollouts && allFirmwareRollouts.allFirmwareRollouts.length > 0 ? (
        allFirmwareRollouts.allFirmwareRollouts.map((firmwareRollout, i) => {
          return (
            <tr
              key={i}
              className="bg-white  text-gray-900 whitespace-nowrap"
              {...(firmwareRollout?.status === "Failed" && {
                onClick: () => {
                  console.log("hi");
                },
              })}>
              <th scope="row" className="px-6 py-4">
                <div className="flex flex-row items-center " onClick={() => {}}>
                  {firmwareRollout?.firmwareUnit?.name}
                  {firmwareRollout?.status === "Failed" && (
                    <IconWarning
                      className="h-4 w-4 ml-2  cursor-pointer"
                      onClick={() => {
                        diagnosticFilterVar({
                          ...diagnosticFilter,
                          searchText: firmwareRollout.firmwareUnit.name,
                          types: ["FirmwareUpdate"],
                        });

                        history.push(`/iot/diagnostic`);
                      }}
                    />
                  )}
                </div>
              </th>
              <td className="px-6 py-4 flex justify-left flex-grow flex-wrap">
                {firmwareRollout?.division && (
                  <Tag
                    key={i}
                    label={firmwareRollout.division.name}
                    tooltip={findParentDivisions(firmwareRollout.division)
                      .map((div) => div.name)
                      .join(" / ")}
                    type={"Gray"}></Tag>
                )}
              </td>
              <td
                className="px-6 py-4 cursor-pointer"
                onClick={() => {
                  deviceFilterVar({
                    ...deviceFilter,
                    searchText: firmwareRollout.firmwareUnit.name,
                  });
                  activeInfo({
                    division: firmwareRollout.division,
                    account: firmwareRollout.division.account,
                  });

                  history.push(`/iot/devices`);
                }}>
                {firmwareRollout?.install}
              </td>
              <td className="px-6 py-4 flex justify-left flex-grow flex-wrap">
                {firmwareRollout?.status && (
                  <Tag
                    label={firmwareRollout.status}
                    type={
                      firmwareRollout.status === "Failed"
                        ? "Red"
                        : firmwareRollout.status === "Ongoing"
                          ? "Green"
                          : "Blue"
                    }></Tag>
                )}
              </td>
              <td className="px-6 py-4 ">
                {firmwareRollout?.rolledOutAt
                  ? new Date(firmwareRollout.rolledOutAt).toLocaleString("sv-SE", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })
                  : ""}
              </td>
              <td>
                <div className="flex flex-row flex-nowrap justify-end items-center">
                  {userData?.user?.permissions.includes("ViewDiagnostic") && (
                    <div
                      className="pl-6 py-4 flex flex-row"
                      onClick={() => {
                        diagnosticFilterVar({
                          ...diagnosticFilter,
                          searchText: firmwareRollout.firmwareUnit.name,
                          types: ["FirmwareUpdate"],
                        });

                        history.push(`/iot/diagnostic`);
                      }}>
                      <IconInfo className="h-5 w-5 mr-2 cursor-pointer" title="Diagnostic for firmware" />
                    </div>
                  )}
                  {userData?.user?.permissions.includes("DeleteFirmware") && (
                    <div
                      className="pr-6 py-4 flex flex-row"
                      onClick={() => {
                        showConfirmDialogVar({
                          title: "Delete firmware rollout",
                          message: "Are you sure?",
                          confirmText: "Delete",
                          onConfirm: async () => {
                            await deleteFirmwareRollout({
                              variables: {
                                firmwareRolloutId: firmwareRollout.id,
                              },
                            });
                            allFirmwareRolloutsRefetch();
                            showConfirmDialogVar(null);
                          },
                          onCancel: () => {
                            showConfirmDialogVar(null);
                          },
                        });
                      }}>
                      <IconDelete className="h-5 w-5 mr-2 cursor-pointer" title="Remove firmware rollout" />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={6}>
            <div className="py-4 px-6 whitespace-nowrap"> No firmware rollouts found </div>
          </td>
        </tr>
      )}
      {allFirmwareRolloutsLoading && (
        <tr>
          <td colSpan={6}>
            <div className="m-6 flex justify-center">
              <Spinner />
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default TableRowFirmreRollouts;
