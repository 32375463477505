import React, { useEffect, useState } from "react";
import { IoMdClose as IconClose } from "react-icons/io";
import { MdArrowBackIos as IconBack } from "react-icons/md";
import { motion } from "framer-motion";
import Tooltip from "./Tooltip";

type SelectBoxType = {
  multiple: boolean;
  items: Array<{ id?: string; name?: string }>;
  initValue?: { id?: string; name?: string };
  disabled?: boolean;
  onChange?: Function;
  initValues?: Array<{ id?: string; name?: string }>;
  cannotBeEmpty?: boolean;
  update?: boolean;
};

export default function SelectBox(props: SelectBoxType) {
  const { multiple, items, initValue, disabled, onChange, initValues, cannotBeEmpty, update } = props;
  const [dropdown, setDropdown] = useState(false);
  let init = initValues;
  if (!init && initValue) {
    init = [initValue];
  }
  if (!init) {
    init = [];
  }
  const [selectedItems, setSelected] = useState(init);
  //Force update
  useEffect(() => {
    if (initValue?.id && selectedItems[0]?.id && initValue.id !== selectedItems[0].id) setSelected([initValue]);
  }, [initValue]);
  //Force update
  useEffect(() => {
    setSelected(init);
  }, [update]);

  const toogleDropdown = () => {
    if (!disabled) {
      setDropdown(!dropdown);
    }
  };

  const addTag = (item) => {
    if (!disabled) {
      if (multiple) {
        if (selectedItems.find((selected) => selected.id === item.id)) {
          const filtered = selectedItems.filter((selected) => selected.id !== item.id);
          if (cannotBeEmpty && !filtered.length) {
            return;
          }
          setSelected(filtered);
        } else {
          setSelected(selectedItems.concat(item));
        }
      } else {
        setSelected([item]);
      }
      setDropdown(false);
    }
  };

  const removeTag = (item) => {
    if (!disabled) {
      const filtered = selectedItems.filter((e) => e !== item);
      if (cannotBeEmpty && !filtered.length) {
        return;
      }
      setSelected(filtered);
    }
  };

  useEffect(() => {
    if (onChange && selectedItems !== init) {
      onChange(selectedItems);
    }
  }, [selectedItems]);

  return (
    <div
      className="relative"
      style={{ minWidth: 100, WebkitTapHighlightColor: "transparent", opacity: disabled ? 0.5 : 1 }}>
      <div className="flex flex-col items-center relative">
        <div className="w-full">
          <div
            style={{ minHeight: "2.6rem", cursor: disabled ? "default" : "pointer" }}
            className={
              "flex border border-gray-300 bg-gray-100 rounded-lg " +
              (dropdown ? "border-bubl-green " : " border-gray-300")
            }
            onClick={toogleDropdown}>
            <div className="flex flex-auto flex-wrap items-center">
              {selectedItems?.map((tag, index) => {
                return (
                  <React.Fragment key={index}>
                    {multiple ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTag(tag);
                        }}
                        className="h-6 cursor-pointer flex justify-center items-center m-1 font-medium px-2 rounded-full border-2 bg-gray-300 capitalize">
                        <div className="mx-4 text-sm">{tag.name}</div>
                        <IconClose style={{ fill: "rgb(17 24 39)" }} className="h-3" />
                      </div>
                    ) : (
                      <div className="w-full items-center flex pl-2 text-gray-900 capitalize">
                        <div className="mx-2 text-sm leading-6">{tag.name}</div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="text-gray-900 w-8 py-1 pl-2 pr-1 flex items-center border border-none ">
              <button
                style={{ cursor: disabled ? "default" : "pointer" }}
                className="w-6 h-6 text-gray-900 outline-none focus:outline-none">
                <IconBack style={{ fill: "rgb(17 24 39)" }} className={`h-4 transform transition-all -rotate-90 `} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {dropdown ? <Dropdown list={items} addItem={addTag}></Dropdown> : null}
    </div>
  );
}

const Dropdown = ({ list, addItem }) => {
  return (
    <div
      id="dropdown"
      className="w-full absolute shadow top-100 text-gray-900 bg-white z-40 left-0  rounded-lg  max-h-select overflow-y-auto">
      <div className="flex flex-col w-full">
        {list?.map((item, key) => {
          let label = "";
          if (item.name === "SuperAdmin") {
            label = "Gives user ALL permissions and access to ALL accounts and groups";
          } else if (item.name === "UserAdmin") {
            label =
              "Gives permissions to add, edit and disable users and their user roles for the selected account and groups";
          } else if (item.name === "FirmwareAdmin") {
            label =
              "Gives permission to add and remove firmware, do firmware rollouts for the selected account and groups";
          } else if (item.name === "DeviceAdmin") {
            label =
              "Gives permission to the user to add devices and connect the BLE MAC-address to serial numbers through import of Excel/CSV document for the selected account and groups.";
          } else if (item.name === "DiagnosticViewer") {
            label =
              "Gives permission to search and view diagnostic information collected from devices and their respective device information for the selected account and groups.";
          }
          return (
            <div
              key={key}
              className="cursor-pointer w-full border-gray-300  border-b hover:bg-gray-100"
              onClick={() => addItem(item)}>
              <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:bg-gray-100 ">
                <div className="w-full items-center flex">
                  {item.img && <motion.img className="rounded-full object-cover h-6 w-6" src={item.img} />}
                  {label !== "" ? (
                    <Tooltip
                      label={label}
                      top={"-10px"}
                      left={"100px"}
                      max={"246px"}
                      childern={<div className="mx-2 leading-6 text-sm capitalize">{item.name}</div>}></Tooltip>
                  ) : (
                    <div className="mx-2 leading-6 text-sm capitalize">{item.name}</div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
