import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { MdOutlineClose as IconClose } from "react-icons/md";
import { showConfirmDialogVar, showPopupVar } from "../../cache";
import { useReactiveVar } from "@apollo/client";

type Props = {
  chiled: JSX.Element;
};

function Popup(props: Props) {
  const { chiled } = props;
  const popupRef = useRef(null);
  const showPopup = useReactiveVar(showPopupVar);
  const alertPopup = useReactiveVar(showConfirmDialogVar);
  function save() {
    showPopup.save();
    showConfirmDialogVar(null);
  }
  // Close popup when clicking outside of it
  function useOutsideAlerter(ref, func) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          func();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, func]);
  }
  useOutsideAlerter(popupRef, () => {
    if (!alertPopup) {
      if (!showPopup.outsideSafety) {
        showPopupVar(null);
      } else {
        showConfirmDialogVar({
          title: "Unsaved changes",
          message: "Are you sure?",
          confirmText: "Discard",
          saveText: "Save",
          onConfirm: async () => {
            showPopupVar(null);
            showConfirmDialogVar(null);
          },
          onSave: save,
          onCancel: () => {
            showConfirmDialogVar(null);
          },
        });
      }
    }
  });

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="fixed h-full w-full z-50 overflow-hidden top-0 left-0 right-0 bottom-0"
        style={{ backgroundColor: "#00000062" }}></motion.div>

      <motion.div
        ref={popupRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="fixed m-auto top-0 bottom-0 right-0 left-0 z-50  whitespace-pre-line rounded-lg w-fit h-fit bg-white  p-1 pt-8">
        <div
          className="absolute top-3 right-3"
          onClick={(e) => {
            e.preventDefault();
            if (!showPopup.outsideSafety) {
              showPopupVar(null);
            } else {
              showConfirmDialogVar({
                title: "Unsaved changes",
                message: "Are you sure?",
                confirmText: "Discard",
                saveText: "Save",
                onConfirm: async () => {
                  showPopupVar(null);
                  showConfirmDialogVar(null);
                },
                onSave: save,
                onCancel: () => {
                  showConfirmDialogVar(null);
                },
              });
            }
          }}>
          <IconClose className="h-6 w-6 cursor-pointer" style={{ fill: "black" }} />
        </div>
        <div className=" w-fit  overflow-auto  p-8 pt-0" style={{ maxHeight: "80vh" }}>
          {chiled}
        </div>
      </motion.div>
    </>
  );
}

export default Popup;
