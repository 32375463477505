import { useEffect, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  AllFirmwareTypesQuery,
  AllFirmwareTypesQueryVariables,
  CreateFirmwareMutation,
  CreateFirmwareMutationVariables,
  Firmware,
  UpdateFirmwareMutation,
  UpdateFirmwareMutationVariables,
} from "../../graphql/graphqlTypes";
import { CREATE_FIRMWARE, UPDATE_FIRMWARE } from "../../graphql/mutations";
import { showPopupVar } from "../../cache";
import Button from "../button/Button";
import TextInput from "../inputs/TextInput";
import DropZone from "../inputs/Dropzone";
import { GET_FIRMWARE_TYPES } from "../../graphql/queries";
import SelectInput from "../inputs/SelectInput";

type PropsPopup = {
  refetch: Function;
  firmware?: Firmware;
};
function FirmwarePopup({ refetch, firmware }: PropsPopup) {
  const [name, setName] = useState(firmware ? firmware.name : "");
  const [description, setDescription] = useState(firmware ? firmware.description : "");
  const [file, setFile] = useState(null);
  const [type, setType] = useState(firmware ? firmware.firmware.type : null);

  const showPopup = useReactiveVar(showPopupVar);
  const [createFirmware] = useMutation<CreateFirmwareMutation, CreateFirmwareMutationVariables>(CREATE_FIRMWARE);
  const [updateFirmware] = useMutation<UpdateFirmwareMutation, UpdateFirmwareMutationVariables>(UPDATE_FIRMWARE);
  const { data: allFirmwareTypes } = useQuery<AllFirmwareTypesQuery, AllFirmwareTypesQueryVariables>(
    GET_FIRMWARE_TYPES,
  );
  async function handelSave() {
    if (firmware) {
      if (name && type) {
        await updateFirmware({
          variables: { firmwareUnitId: firmware.id, name: name, description: description, type: type },
        });
      }
    } else {
      if (name && file && type) {
        await createFirmware({
          variables: { name: name, description: description, type: type, firmware: file },
        });
      }
    }

    refetch();
    showPopupVar(null);
  }
  useEffect(() => {
    showPopupVar({ ...showPopup, save: handelSave });
  }, [name, description, file, type]);
  return (
    <div className="flex flex-col justify-between px-2" style={{ width: "450px" }}>
      <div className="text-lg font-bold mt-2 mb-8 ">{firmware ? "Edit Firmware" : "New Firmware"}</div>
      <TextInput
        id={"name"}
        label={"Name"}
        value={name}
        onChange={(value) => {
          setName(value);
          showPopupVar({ ...showPopup, outsideSafety: true });
        }}
        type={"text"}
      />
      <TextInput
        id={"description"}
        label={"Description"}
        value={description}
        onChange={(value) => {
          setDescription(value);
          showPopupVar({ ...showPopup, outsideSafety: true });
        }}
        type={"text"}
      />
      <div className="mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-900 ">FirmwareType</label>
        <SelectInput
          multiple={false}
          items={allFirmwareTypes?.allFirmwareTypes?.map((e) => {
            return { id: e, name: e };
          })}
          initValue={{ id: type, name: type }}
          onChange={(e) => {
            setType(e[0].name);
            showPopupVar({ ...showPopup, outsideSafety: true });
          }}
        />
      </div>
      {!firmware && <DropZone label={"Firmware"} setPackage={(file: File) => setFile(file)} />}

      <div className="-ml-4">
        <Button
          label={firmware ? "Update" : "Upload"}
          disable={firmware ? !(name && type) : !(name && file && type)}
          onClick={() => {
            handelSave();
          }}
        />
      </div>
    </div>
  );
}

export default FirmwarePopup;
