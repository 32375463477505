import { useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { diagnosticFilterVar } from "../cache";

type PaginationProps = {
  totalPages: number;
  active: number;
};

export default function Pagination({ totalPages, active }: PaginationProps) {
  const [pages, setPages] = useState([]);
  const diagnosticFilter = useReactiveVar(diagnosticFilterVar);
  useEffect(() => {
    //Start with first
    let visibleNumbers = [1]; // < 1 ... 5 6 7... 32 >
    //Add all exept first and last
    if (totalPages < 5) for (let i = 2; i < totalPages; i++) visibleNumbers.push(i);
    else if (active - 1 < 2) {
      visibleNumbers.push(2);
      visibleNumbers.push(3);
      visibleNumbers.push(-1);
    } else if (active + 1 >= totalPages) {
      visibleNumbers.push(-1);
      visibleNumbers.push(totalPages - 2);
      visibleNumbers.push(totalPages - 1);
    } else {
      if (active - 2 > 1) {
        visibleNumbers.push(-1);
      }
      visibleNumbers.push(active - 1);
      visibleNumbers.push(active);
      visibleNumbers.push(active + 1);
      if (active + 2 < totalPages) {
        visibleNumbers.push(-1);
      }
    }
    //Add last
    if (totalPages > 1) {
      visibleNumbers.push(totalPages);
    }
    setPages(visibleNumbers);
  }, [totalPages, active]);
  return (
    <div className="flex flex-row justify-center my-4">
      <PaginationBubl
        lable={"<"}
        onClick={() => {
          //Previous
          if (1 <= active - 1) {
            diagnosticFilterVar({
              ...diagnosticFilter,
              skip: diagnosticFilter.skip - diagnosticFilter.take,
            });
          }
        }}
        active={false}
      />
      {pages.map((label, i) => {
        return (
          <PaginationBubl
            key={i}
            lable={label !== -1 ? label.toString() : "..."}
            active={label === active}
            onClick={() => {
              if (label !== -1) {
                diagnosticFilterVar({
                  ...diagnosticFilter,
                  skip: (label - 1) * diagnosticFilter.take,
                });
              }
            }}
          />
        );
      })}
      <PaginationBubl
        lable={">"}
        onClick={() => {
          if (totalPages >= active + 1) {
            diagnosticFilterVar({
              ...diagnosticFilter,
              skip: diagnosticFilter.skip + diagnosticFilter.take,
            });
          }
        }}
        active={false}
      />
    </div>
  );
}

type PaginationBublProps = {
  lable: string;
  onClick: Function;
  active: boolean;
};
function PaginationBubl({ lable, onClick, active }: PaginationBublProps) {
  return (
    <>
      {active ? (
        <div
          className="cursor-pointer flex justify-center items-center border-2 border-bubl-green bg-white  text-bubl-green font-semibold h-6 w-6 rounded-lg ml-4 text-xs"
          onClick={(e) => onClick(lable)}>
          {lable}
        </div>
      ) : lable === "..." ? (
        <div
          className=" flex justify-center items-end text-bubl-green font-semibold h-6 w-6 rounded-lg ml-4 text-xs"
          onClick={(e) => onClick(lable)}>
          {lable}
        </div>
      ) : (
        <div
          className="cursor-pointer flex justify-center items-center border-2 border-bubl-green bg-bubl-green hover:bg-white text-white hover:text-bubl-green font-semibold h-6 w-6 rounded-lg ml-4 text-xs"
          onClick={(e) => onClick(lable)}>
          {lable}
        </div>
      )}
    </>
  );
}
