import React from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  GetDivisionUserRolesQuery,
  GetDivisionUserRolesQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  UpdateDivisionUserRolesMutation,
  UpdateDivisionUserRolesMutationVariables,
} from "../../graphql/graphqlTypes";
import { GET_DIVISION_USER_ROLES, GET_USER } from "../../graphql/queries";
import { MdDelete as IconDelete } from "react-icons/md";
import { MdEdit as IconEdit } from "react-icons/md";
import { activeInfo, adminFilterVar, showConfirmDialogVar, showPopupVar, userRolesFilterVar } from "../../cache";
import UserRolePopup from "../popups/UserRolePopup";
import Spinner from "../Spinner";
import Tag from "../Tag";
import { UPDATE_DIVISION_USER_ROLES } from "../../graphql/mutations";
import Tooltip from "../inputs/Tooltip";

function TableRowUserRole() {
  const active = useReactiveVar(activeInfo);
  const adminFilter = useReactiveVar(adminFilterVar);
  const userRolesFilter = useReactiveVar(userRolesFilterVar);
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  const {
    data: divisionUserRolesData,
    loading: divisionUserRolesLoading,
    refetch: divisionUserRolesRefetch,
  } = useQuery<GetDivisionUserRolesQuery, GetDivisionUserRolesQueryVariables>(GET_DIVISION_USER_ROLES, {
    variables: {
      divisionId: active?.division?.id,
      roles: adminFilter.roles,
      sort: userRolesFilter.sort,
      order: userRolesFilter.order,
    },
    fetchPolicy: "no-cache",
    skip: !active?.division?.id,
  });
  const [updateDivisionUserRoles] = useMutation<
    UpdateDivisionUserRolesMutation,
    UpdateDivisionUserRolesMutationVariables
  >(UPDATE_DIVISION_USER_ROLES);
  function UpdateRefetch() {
    divisionUserRolesRefetch();
    userRolesFilterVar({ ...userRolesFilter, order: !userRolesFilter.order });
    userRolesFilterVar({ ...userRolesFilter, order: !userRolesFilter.order });
  }
  return (
    <>
      {divisionUserRolesData?.userDivisionUserRoles && divisionUserRolesData.userDivisionUserRoles.length > 0 ? (
        divisionUserRolesData.userDivisionUserRoles.map((user, i) => {
          return (
            <tr key={i} className="bg-white  text-gray-900 whitespace-nowrap">
              <th scope="row" className="px-6 py-4">
                {user.name}
              </th>
              <td className="px-6 py-4 flex justify-left flex-grow flex-wrap">
                {user?.roles &&
                  user.roles.map((role, i) => {
                    if (role?.divisions?.some((divison) => divison.id === active.division.id))
                      return <Tag key={i + role.name} label={role.name} type={"Gray"}></Tag>;
                  })}
              </td>
              <td className="px-6 py-4">
                {userData?.user?.permissions.includes("EditUser") && user.id !== userData.user.id && (
                  <div className="flex flex-row flex-nowrap justify-end items-center">
                    <div
                      title="Edit user role"
                      onClick={() => {
                        showPopupVar({
                          chiled: <UserRolePopup refetch={UpdateRefetch} initUser={user} />,
                        });
                      }}>
                      <IconEdit className="h-5 w-5 mr-2 cursor-pointer" />
                    </div>

                    <div
                      title="Remove user role"
                      onClick={() => {
                        showConfirmDialogVar({
                          title: "Delete userrole",
                          message: "Are you sure?",
                          confirmText: "Delete",
                          onConfirm: async () => {
                            await updateDivisionUserRoles({
                              variables: {
                                userId: user.id,
                                divisionId: active.division.id,
                                addedRoleIds: [],
                                removedRoleIds: user.roles.map((e) => {
                                  return e.id;
                                }),
                              },
                            });
                            UpdateRefetch();
                            showConfirmDialogVar(null);
                          },
                          onCancel: () => {
                            showConfirmDialogVar(null);
                          },
                        });
                      }}>
                      <IconDelete className="h-5 w-5 mr-2 cursor-pointer" />
                    </div>
                  </div>
                )}
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={3}>
            <div className="py-4 px-6 whitespace-nowrap"> No userroles </div>
          </td>
        </tr>
      )}
      {divisionUserRolesLoading && (
        <tr>
          <td colSpan={3}>
            <div className="m-6 flex justify-center">
              <Spinner />
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default TableRowUserRole;
