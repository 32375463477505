import { useRef } from "react";
import { IoMdSearch as IconSearch } from "react-icons/io";
import { MdOutlineClose as IconClose } from "react-icons/md";
import { useDebounceCallback } from "usehooks-ts";
type Props = {
  value: string;
  onChange: (value: string) => void;
  onSearch: (value: string) => void;
};

function SearchInput(props: Props) {
  const { onChange, onSearch, value } = props;

  const debouncedOnSearch = useDebounceCallback(onSearch, 500);

  const refSearchInput = useRef(null);
  return (
    <div className="relative m-2">
      <input
        ref={refSearchInput}
        type="search"
        id={"search-input"}
        onChange={(e) => {
          onChange(e.target.value);
          debouncedOnSearch(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") debouncedOnSearch(value);
        }}
        placeholder="Search"
        value={value}
        className="pr-6 appearance-none bg-gray-100 border border-gray-300 text-gray-900 focus:border-bubl-green outline-none text-sm rounded-lg block w-full py-1.5 px-3 disabled:text-opacity-50"
      />
      {value === "" ? (
        <IconSearch
          className="absolute h-5 w-5 top-2 right-2 cursor-pointer text-gray-600  transform transition-all active:scale-95"
          onClick={() => refSearchInput?.current.focus()}
        />
      ) : (
        <IconClose
          className="absolute h-5 w-5 top-2 right-2 cursor-pointer text-gray-600  transform transition-all active:scale-95"
          onClick={() => {
            onChange("");
            debouncedOnSearch("");
          }}
        />
      )}
    </div>
  );
}

export default SearchInput;
