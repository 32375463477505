import { useQuery, useReactiveVar } from "@apollo/client";
import { useCallback, useState } from "react";
import { IoMdSearch as IconSearch } from "react-icons/io";
import { activeInfo, deviceFilterVar, showPopupVar } from "../cache";
import Navbar from "../components/Menu/Navbar";
import Sidebar from "../components/Menu/Sidebar";
import Table from "../components/Table/Table";
import TableRowDevices from "../components/Table/TableRowDevices";
import Button from "../components/button/Button";
import IconButton from "../components/button/IconButton";
import SearchInput from "../components/inputs/SearchInput";
import ImportDevicePopup from "../components/popups/ImportDevicePopup";
import { GetUserQuery, GetUserQueryVariables } from "../graphql/graphqlTypes";
import { GET_USER } from "../graphql/queries";

function Devices() {
  const [searchText, setSearchText] = useState("");
  const deviceFilter = useReactiveVar(deviceFilterVar);
  //Refetching data after update
  const active = useReactiveVar(activeInfo);

  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });

  const onSearch = useCallback(
    (value: string) => deviceFilterVar({ ...deviceFilter, searchText: value }),
    [deviceFilter],
  );

  //Featch all devices that not have a device unit
  if (!userData?.user?.permissions) return <></>;
  return (
    <div className="w-full h-full bg-gray-100 flex flex-col">
      <Navbar />
      <div className="flex flex-row h-full">
        {/* Sidebar */}
        <Sidebar />
        {/* Content  */}
        <div
          className="m-8 rounded-xl border bg-white flex-grow p-8 flex flex-col "
          style={{ width: "calc(100% - 20rem)", maxHeight: "calc(100vh - 9rem )" }}>
          <div className="flex justify-between mb-4">
            <div>
              <div className="flex flex-row items-center">
                {searchText !== "" && <IconSearch className="h-6 w-6  cursor-pointer text-gray-600 mr-2" />}
                <div className="text-lg font-bold mt-2 mb-2 whitespace-nowrap overflow-hidden text-ellipsis">
                  {active?.division?.name ? "Devices: " + active.division.name : "Choose an Account or Group"}
                </div>
                {searchText !== "" && (
                  <div className="ml-12">
                    <IconButton
                      label={"Exit search"}
                      onClick={() => {
                        setSearchText("");
                        deviceFilterVar({ ...deviceFilter, searchText: "" });
                      }}
                    />
                  </div>
                )}
              </div>
              {active?.division?.id && (
                <span className="text-xs text-black text-opacity-60" style={{ letterSpacing: "0.02rem" }}>
                  {searchText !== "" ? "Searching in selected group." : "Device list in selected group."}
                </span>
              )}
            </div>
            {active?.division?.id && userData.user.permissions.includes("ViewDevice") && (
              <div className="flex flex-row">
                <SearchInput
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e);
                  }}
                  onSearch={onSearch}
                />
              </div>
            )}
          </div>
          {active?.division?.id && (
            <div className="my-6 overflow-auto">
              <Table
                headers={
                  deviceFilter.searchText !== ""
                    ? ["Mac address", "Serial Number", "Firmware", "Hardware", "Group", "Last Activity", ""]
                    : ["Mac address", "Serial Number", "Firmware", "Hardware", "Last Activity", ""]
                }
                Row={<TableRowDevices />}
                sort={(name) => {
                  if (deviceFilter.sort === name) {
                    deviceFilterVar({ ...deviceFilter, order: !deviceFilter.order });
                  } else {
                    deviceFilterVar({ ...deviceFilter, sort: name });
                  }
                }}
                activeSort={deviceFilter.sort}
                order={deviceFilter.order}
              />
            </div>
          )}
          {userData.user.permissions.includes("CreateDevice") &&
            active?.division &&
            active?.division?.name !== "Accounts" && (
              <div>
                <Button
                  label={"Import Devices"}
                  disable={true}
                  onClick={() => {
                    showPopupVar({
                      chiled: <ImportDevicePopup />,
                    });
                  }}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default Devices;
