import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Spinner from "../Spinner";
import Button from "../button/Button";

type Props = {
  title: string;
  message: string;
  confirmText: string;
  saveText?: string;
  onConfirm: Function;
  onSave?: Function;
  onCancel?: Function;
};

function AlertPopup(props: Props) {
  const { title, message, confirmText, saveText, onConfirm, onCancel, onSave } = props;
  const [loading, setLoading] = useState(false);

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 640) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="fixed h-full w-full z-50 overflow-hidden top-0 left-0"
        style={{ backgroundColor: "#00000099", zIndex: 55 }}></motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        style={{ minHeight: "fit-content", minWidth: mobile ? "80%" : "fit-content", zIndex: 60, maxWidth: "500px" }}
        className="fixed m-auto top-0 bottom-0 right-0 left-0 z-50 whitespace-pre-line rounded-md w-2/6 h-64 sm:h-44 bg-white px-8 py-2  ">
        <div className="flex flex-col justify-around  h-full " onClick={(e) => e.stopPropagation()}>
          <div className="mt-6">
            <label className="block mb-2 text-base font-bold text-gray-900 mr-2 text-center">{title}</label>
            <label className="block mb-2 text-sm text-gray-900 mr-2 text-center tracking-wide">{message}</label>
          </div>
          <div className="flex flex-col sm:flex-row justify-center">
            <Button
              label={confirmText}
              disable={false}
              onClick={(e) => {
                if (!loading) {
                  setLoading(true);
                  e.stopPropagation();
                  onConfirm();
                }
              }}
            />
            {onSave && (
              <Button
                label={saveText}
                disable={false}
                onClick={(e) => {
                  if (!loading) {
                    e.stopPropagation();
                    onSave();
                  }
                }}
              />
            )}
            {onCancel && (
              <Button
                label={"Cancel"}
                disable={false}
                onClick={(e) => {
                  if (!loading) {
                    e.stopPropagation();
                    onCancel();
                  }
                }}
              />
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default AlertPopup;
