import { useEffect, useState } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  SendRegistrationMailMutation,
  SendRegistrationMailMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserRoleDivisionsMutation,
  UpdateUserRoleDivisionsMutationVariables,
  User,
} from "../../graphql/graphqlTypes";
import { CREATE_USER, SEND_REGISTRATION_MAIL, UPDATE_USER, UPDATE_USER_ROLE_DIVITIONS } from "../../graphql/mutations";
import { activeInfo, showPopupVar } from "../../cache";
import Button from "../button/Button";
import TextInput from "../inputs/TextInput";
import Checkbox from "../inputs/Checkbox";
import RoleList from "../inputs/CheckboxList";
import IconButton from "../button/IconButton";
import { MdEmail as IconMail } from "react-icons/md";
type PropsPopup = {
  refetch: Function;
  user?: User;
};
function UserPopup({ refetch, user }: PropsPopup) {
  const [name, setName] = useState(user?.name ? user.name : "");
  const [email, setEmail] = useState(user?.email ? user.email : "");
  const [emailComplete, setEmailComplete] = useState(false);
  const [rolesComplete, setRolesComplete] = useState(false);
  const [sendRegestrationEmail, setSendRegestrationEmails] = useState(!user ? true : false);
  const [selectedRoles, setSelectedRoles] = useState(user?.roles ? user.roles : []);
  const [error, setError] = useState(false);

  const active = useReactiveVar(activeInfo);
  const showPopup = useReactiveVar(showPopupVar);
  const [createUser] = useMutation<CreateUserMutation, CreateUserMutationVariables>(CREATE_USER);
  const [updateUser] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER);
  const [sendRegEmail] = useMutation<SendRegistrationMailMutation, SendRegistrationMailMutationVariables>(
    SEND_REGISTRATION_MAIL,
  );
  const [updateUserRoles] = useMutation<UpdateUserRoleDivisionsMutation, UpdateUserRoleDivisionsMutationVariables>(
    UPDATE_USER_ROLE_DIVITIONS,
  );
  useEffect(() => {
    if (email && email.match(/^\S+@\S+\.\S+$/)) {
      setEmailComplete(true);
    } else {
      setEmailComplete(false);
    }
  }, [email]);
  useEffect(() => {
    if (
      selectedRoles.length &&
      selectedRoles.filter((role) => {
        if (role.name === "SuperAdmin") {
          return false;
        } else {
          return !role.divisions.length;
        }
      }).length === 0
    ) {
      setRolesComplete(true);
    } else {
      setRolesComplete(false);
    }
  }, [selectedRoles]);
  async function handelSave() {
    let errorSaving = false;
    // Create or Update user
    if (!user && emailComplete) {
      let { data } = await createUser({
        variables: { name: name, email: email, disabled: false, accountId: active.account.id },
      });
      user = data.createUser.user;
      if (!data?.createUser?.success) {
        setError(true);
        errorSaving = true;
        return;
      }
    } else if (user && user.id && emailComplete) {
      let { data } = await updateUser({
        variables: { userId: user.id, name: name, email: email, disabled: false },
      });
      if (!data?.updateUser?.success) {
        setError(true);
        errorSaving = true;
        return;
      }
    }
    // Send email
    if (sendRegestrationEmail && emailComplete) {
      await sendRegEmail({
        variables: { userId: user.id },
      });
    }
    // Update roles
    if (user) {
      for (let i = 0; i < selectedRoles.length; i++) {
        let role = selectedRoles[i];
        const divisions = role.divisions.map((division) => {
          return division.id;
        });
        let res = await updateUserRoles({
          variables: {
            userId: user.id,
            userRoleId: role.id,
            divisionIds: divisions,
          },
        }).catch((e) => {
          setError(true);
          errorSaving = true;
          return null;
        });
        if (!res?.data?.updateUserRoleDivisions?.success) {
          setError(true);
          errorSaving = true;
          return;
        }
      }
      //Remove role
      if (user?.roles) {
        let removedRoles = user.roles.filter(
          (existingRole) =>
            !selectedRoles.some((incomingRole) => {
              return incomingRole.id === existingRole.id;
            }),
        );
        for (let i = 0; i < removedRoles.length; i++) {
          let role = removedRoles[i];
          let res = await updateUserRoles({
            variables: {
              userId: user.id,
              userRoleId: role.id,
              divisionIds: [],
            },
          }).catch((e) => {
            setError(true);
            errorSaving = true;
            return null;
          });
          if (!res?.data?.updateUserRoleDivisions?.success) {
            setError(true);
            errorSaving = true;
            return;
          }
        }
      }
    }
    if (!errorSaving) {
      showPopupVar(null);
      await refetch();
    }
  }
  useEffect(() => {
    showPopupVar({ ...showPopup, save: handelSave });
  }, [email, name, active, user, selectedRoles]);
  return (
    <div className="flex flex-col justify-between px-2" style={{ width: "450px" }}>
      <div className="text-lg font-bold mt-2 mb-2 ">{!user ? "New User" : "Edit User"}</div>
      <TextInput
        id={"name"}
        label={"Name"}
        value={name}
        onChange={(value) => {
          setName(value);
          showPopupVar({ ...showPopup, outsideSafety: true });
        }}
        type={"text"}
      />
      <TextInput
        id={"email"}
        label={"Email"}
        value={email}
        onChange={(value) => {
          setEmail(value);
          showPopupVar({ ...showPopup, outsideSafety: true });
        }}
        type={"text"}
      />
      <RoleList selectedRoles={selectedRoles} setSelectedRoles={setSelectedRoles} />

      {!user ? (
        <div className="-ml-2">
          <Checkbox
            id={"regietrationEmail"}
            label={"Send registration email"}
            checked={sendRegestrationEmail}
            onChange={(value) => {
              setSendRegestrationEmails(value);
              showPopupVar({ ...showPopup, outsideSafety: true });
            }}
            textStyle="font-bold"
          />
        </div>
      ) : (
        user.lastLoggedIn === null && (
          <div className="-ml-2 mb-2">
            <IconButton
              label={"Send registration email"}
              Icon={IconMail}
              onClick={async () => {
                await sendRegEmail({
                  variables: { userId: user.id },
                });
              }}
            />
          </div>
        )
      )}
      <div className="-ml-4">
        <Button
          label={!user ? "Create" : "Update"}
          disable={!(name && emailComplete && rolesComplete)}
          onClick={() => {
            if (name && emailComplete && rolesComplete) {
              handelSave();
            }
          }}
        />
      </div>
      {error && <div className="text-red-400 text-xs">{!user ? "Error creating user" : "Error updating user"}</div>}
    </div>
  );
}

export default UserPopup;
