import React, { useEffect } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  DeleteUserMutation,
  DeleteUserMutationVariables,
  Division,
  GetAllDivisionUserRolesQuery,
  GetAllDivisionUserRolesQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
} from "../../graphql/graphqlTypes";
import { GET_ALL_DIVISION_USER_ROLES, GET_USER } from "../../graphql/queries";
import { MdEdit as IconEdit } from "react-icons/md";
import { MdDelete as IconDelete } from "react-icons/md";
import { activeInfo, adminFilterVar, showConfirmDialogVar, showPopupVar, userRolesFilterVar } from "../../cache";
import UserPopup from "../popups/UserPopup";
import Spinner from "../Spinner";
import Tag from "../Tag";
import { DELETE_USER } from "../../graphql/mutations";
import Tooltip from "../inputs/Tooltip";

function TableRowAccountUser() {
  const active = useReactiveVar(activeInfo);
  const adminFilter = useReactiveVar(adminFilterVar);
  const userRolesFilter = useReactiveVar(userRolesFilterVar);
  const {
    data: allDivisionUserRolesData,
    loading: allDivisionUserRolesLoading,
    refetch: allDivisionUserRolesRefetch,
  } = useQuery<GetAllDivisionUserRolesQuery, GetAllDivisionUserRolesQueryVariables>(GET_ALL_DIVISION_USER_ROLES, {
    fetchPolicy: "no-cache",
    variables: {
      accountId: active?.account?.id,
      roles: adminFilter.roles,
      sort: adminFilter.sort,
      order: adminFilter.order,
    },
    skip: !active?.account?.id,
  });
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  const [deleteUser] = useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DELETE_USER);
  async function refetch() {
    adminFilterVar({ ...adminFilter, order: !adminFilter.order });
    adminFilterVar({ ...adminFilter, order: !adminFilter.order });
    userRolesFilterVar({ ...userRolesFilter, order: !userRolesFilter.order });
    userRolesFilterVar({ ...userRolesFilter, order: !userRolesFilter.order });
    await allDivisionUserRolesRefetch();
  }
  return (
    <>
      {allDivisionUserRolesData?.allDivisionUserRoles && allDivisionUserRolesData?.allDivisionUserRoles.length > 0 ? (
        allDivisionUserRolesData.allDivisionUserRoles.map((user, i) => {
          return (
            <tr key={i} className="bg-white  text-gray-900 whitespace-nowrap">
              <th scope="row" className="px-6 py-4">
                {user.name}
              </th>
              <td className="px-6 py-4">{user.email}</td>
              <td className="px-6 py-4 flex justify-left flex-grow flex-wrap">
                {user?.roles &&
                  user.roles.map((role, i) => {
                    return <Tag key={i + role.name} label={role.name} type={"Gray"}></Tag>;
                  })}
              </td>
              <td className="px-6 py-4 ">
                {user?.lastLoggedIn ? new Date(user.lastLoggedIn).toLocaleString("sv-SE", { dateStyle: "short" }) : ""}
              </td>
              <td>
                <div className="flex flex-row flex-nowrap justify-end items-center">
                  {userData?.user?.permissions.includes("EditUser") && user.id !== userData.user.id && (
                    <div
                      className=" py-4 flex flex-row mr-2 "
                      onClick={() => {
                        showPopupVar({
                          chiled: <UserPopup refetch={refetch} user={user} />,
                        });
                      }}>
                      <IconEdit className="h-5 w-5 cursor-pointer" title="Edit user" />
                    </div>
                  )}
                  {userData?.user?.permissions.includes("DeleteUser") && user.id !== userData.user.id && (
                    <div
                      title="Remove user"
                      onClick={() => {
                        showConfirmDialogVar({
                          title: "Delete user",
                          message: "Are you sure?",
                          confirmText: "Delete",
                          onConfirm: async () => {
                            await deleteUser({
                              variables: {
                                userId: user.id,
                              },
                            });
                            refetch();
                            showConfirmDialogVar(null);
                          },
                          onCancel: () => {
                            showConfirmDialogVar(null);
                          },
                        });
                      }}>
                      <IconDelete className="h-5 w-5 mr-2 cursor-pointer" />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={6}>
            <div className="py-4 px-6 whitespace-nowrap"> No users </div>
          </td>
        </tr>
      )}
      {allDivisionUserRolesLoading && (
        <tr>
          <td colSpan={6}>
            <div className="m-6 flex justify-center">
              <Spinner />
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default TableRowAccountUser;
