import { useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { Division, GetDivisionsQuery, GetDivisionsQueryVariables } from "../../graphql/graphqlTypes";
import { activeInfo, showPopupVar } from "../../cache";
import Button from "../button/Button";
import SelectInput from "../inputs/SelectInput";
import { GET_DIVISIONS } from "../../graphql/queries";
import Dropzone from "../inputs/Dropzone";

function ImportDevicePopup() {
  const [update, setUpdate] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Division>(null);
  const [divisions, setDivisions] = useState([]);
  const [deviceList, setDeviceList] = useState(null);

  const active = useReactiveVar(activeInfo);
  const showPopup = useReactiveVar(showPopupVar);

  const { data: divisionData } = useQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(GET_DIVISIONS);

  async function handelSave() {
    // TODO Add devices
    // if (selectedGroup) {
    //   let { data } = await createDevice({
    //     variables: { name: name, email: email, disabled: false, accountId: active.account.id },
    //   });
    // }
    //refetch();
    showPopupVar(null);
  }
  useEffect(() => {
    showPopupVar({ ...showPopup, save: handelSave });
  }, [selectedGroup, deviceList]);

  //Get all divisions as a list
  useEffect(() => {
    const generatedDivision: Division[] = [];
    childeDivisons(divisionData.divisions, generatedDivision);
    function childeDivisons(divisions: Division[], array: Division[]) {
      if (divisions?.length < 1) {
        return;
      }
      divisions.map((division) => {
        array.push(division);
        childeDivisons(division.divisions, array);
      });
    }
    setDivisions(generatedDivision);
  }, [divisionData]);

  return (
    <div className="flex flex-col justify-between px-2" style={{ width: "450px" }}>
      <div className="text-lg font-bold mt-2 mb-2 ">Import device list</div>
      <div className=" items-start block mb-2 text-sm font-bold text-gray-900 ">Group</div>
      <SelectInput
        multiple={false}
        items={divisions}
        initValue={active.division}
        onChange={(e: Division) => {
          setSelectedGroup(e);
          setUpdate(!update);
          showPopupVar({ ...showPopup, outsideSafety: true });
        }}
      />
      <div className="my-6">
        <Dropzone
          label={"Device List"}
          setPackage={function (file?: File): Promise<void> {
            throw new Error("Function not implemented.");
          }}
        />
      </div>
      <div className="-ml-4">
        <Button
          label={"Import devices"}
          disable={!selectedGroup}
          onClick={() => {
            handelSave();
          }}
        />
      </div>
    </div>
  );
}

export default ImportDevicePopup;
