import gql from "graphql-tag";
//  ** ACCOUNT **
export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($accountId: String!, $name: String, $disabled: Boolean) {
    updateAccount(accountId: $accountId, name: $name, disabled: $disabled) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($accountId: String!) {
    deleteAccount(accountId: $accountId) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const CREATE_ACCOUNT = gql`
  mutation createAccount($name: String!) {
    createAccount(name: $name) {
      success
      error {
        code
        msg
      }
    }
  }
`;

//  ** USER **
export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;
export const REGISTER = gql`
  mutation register($password: String!, $registerToken: String!) {
    register(password: $password, registerToken: $registerToken) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const CREATE_USER = gql`
  mutation createUser($name: String!, $email: String!, $disabled: Boolean!, $accountId: String!) {
    createUser(name: $name, email: $email, disabled: $disabled, accountId: $accountId) {
      success
      error {
        code
        msg
      }
      user {
        id
        roles {
          name
          id
          divisions {
            id
            name
          }
        }
      }
    }
  }
`;
export const DELETE_USER = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(userId: $userId) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!) {
    recoverPassword(email: $email) {
      success
      error {
        code
        msg
      }
    }
  }
`;

export const SEND_REGISTRATION_MAIL = gql`
  mutation sendRegistrationMail($userId: String!) {
    sendRegistrationMail(userId: $userId) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser($userId: String!, $name: String!, $email: String!, $disabled: Boolean!) {
    updateUser(userId: $userId, name: $name, email: $email, disabled: $disabled) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const UPDATE_USER_ROLE_DIVITIONS = gql`
  mutation updateUserRoleDivisions($userId: String!, $userRoleId: String!, $divisionIds: [String!]!) {
    updateUserRoleDivisions(userId: $userId, userRoleId: $userRoleId, divisionIds: $divisionIds) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const UPDATE_DIVISION_USER_ROLES = gql`
  mutation updateDivisionUserRoles(
    $userId: String!
    $divisionId: String!
    $addedRoleIds: [String!]
    $removedRoleIds: [String!]
  ) {
    updateDivisionUserRoles(
      userId: $userId
      divisionId: $divisionId
      addedRoleIds: $addedRoleIds
      removedRoleIds: $removedRoleIds
    ) {
      success
      error {
        code
        msg
      }
    }
  }
`;

//  ** DIVISION **
export const UPDATE_DIVISION = gql`
  mutation updateDivision($divisionId: String!, $name: String) {
    updateDivision(divisionId: $divisionId, name: $name) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const DELETE_DIVISION = gql`
  mutation deleteDivision($divisionId: String!) {
    deleteDivision(divisionId: $divisionId) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const CREATE_DIVISION = gql`
  mutation createDivision($name: String!, $parentDivisionId: String!) {
    createDivision(name: $name, parentDivisionId: $parentDivisionId) {
      success
      error {
        code
        msg
      }
    }
  }
`;
// ** DEVICE **
export const CREATE_DEVICE = gql`
  mutation createDevice($macAddress: String!, $divisionId: String!, $accountId: String!) {
    createDevice(macAddress: $macAddress, divisionId: $divisionId, accountId: $accountId) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const DELETE_DEVICE = gql`
  mutation deleteDevice($deviceId: String!) {
    deleteDevice(deviceId: $deviceId) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const MOVE_DEVICE = gql`
  mutation moveDevice($deviceId: String!, $divisionId: String!, $accountId: String!) {
    moveDevice(deviceId: $deviceId, divisionId: $divisionId, accountId: $accountId) {
      success
      error {
        code
        msg
      }
    }
  }
`;

// ** FIRMWARE **
export const CREATE_FIRMWARE = gql`
  mutation createFirmware($name: String!, $description: String, $type: String!, $firmware: Upload!) {
    createFirmware(name: $name, description: $description, type: $type, firmware: $firmware) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const DELETE_FIRMWARE = gql`
  mutation deleteFirmware($firmwareId: String!) {
    deleteFirmware(firmwareId: $firmwareId) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const UPDATE_FIRMWARE = gql`
  mutation updateFirmware($firmwareUnitId: String!, $name: String!, $description: String, $type: String!) {
    updateFirmware(firmwareUnitId: $firmwareUnitId, name: $name, description: $description, type: $type) {
      success
      error {
        code
        msg
      }
    }
  }
`;

// ** FIRMWARE ROLLOUTS **
export const CREATE_FIRMWARE_ROLLOUT = gql`
  mutation createFirmwareRollout($firmwareUnitId: String!, $divisionId: String!) {
    createFirmwareRollout(firmwareUnitId: $firmwareUnitId, divisionId: $divisionId) {
      success
      error {
        code
        msg
      }
    }
  }
`;
export const DELETE_FIRMWARE_ROLLOUT = gql`
  mutation deleteFirmwareRollout($firmwareRolloutId: String!) {
    deleteFirmwareRollout(firmwareRolloutId: $firmwareRolloutId) {
      success
      error {
        code
        msg
      }
    }
  }
`;
