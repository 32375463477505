import React, { useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  AllDiagnosticsQuery,
  AllDiagnosticsQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
} from "../../graphql/graphqlTypes";
import { GET_ALL_DIAGNOSTIC, GET_USER } from "../../graphql/queries";
import { MdInfo as IconInfo } from "react-icons/md";
import { deviceFilterVar, diagnosticFilterVar, showPopupVar } from "../../cache";
import Spinner from "../Spinner";
import Tag from "../Tag";
import DiagnosticPopup from "../popups/DiagnosticPopup";
import { useHistory } from "react-router-dom";
import Tooltip from "../inputs/Tooltip";

function TableRowDiagnostic() {
  let history = useHistory();
  const [top, setTop] = useState(0);
  const deviceFilter = useReactiveVar(deviceFilterVar);
  const diagnosticFilter = useReactiveVar(diagnosticFilterVar);
  const { data: allDiagnostic, loading: allDiagnosticLoading } = useQuery<
    AllDiagnosticsQuery,
    AllDiagnosticsQueryVariables
  >(GET_ALL_DIAGNOSTIC, {
    variables: {
      types: diagnosticFilter.types,
      searchText: diagnosticFilter.searchText,
      sort: diagnosticFilter.sort,
      order: diagnosticFilter.order,
      take: diagnosticFilter.take,
      skip: diagnosticFilter.skip,
    },
    fetchPolicy: "network-only",
  });
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  function parsJson(str: string) {
    try {
      return JSON.stringify(JSON.parse(str), undefined, 2);
    } catch (error) {
      console.log("Problem convering to Json object");
    }
    return "";
  }
  return (
    <>
      {allDiagnostic?.allDiagnostics && allDiagnostic.allDiagnostics.length > 0 ? (
        allDiagnostic.allDiagnostics.map((diagnosticError, i) => {
          return (
            <tr key={i} className="bg-white  text-gray-900 whitespace-nowrap">
              <th scope="row" className="px-6 py-4">
                <div
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() => {
                    deviceFilterVar({
                      ...deviceFilter,
                      searchText: diagnosticError?.macAddress ? diagnosticError.macAddress : diagnosticError?.initId,
                    });
                    history.push(`/iot/devices`);
                  }}>
                  {diagnosticError?.macAddress ? diagnosticError.macAddress : diagnosticError?.initId}
                </div>
              </th>
              <td
                onMouseEnter={(e) => {
                  setTop(e.clientY);
                }}
                className={" px-6 py-4 max-w-xs whitespace-nowrap overflow-hidden text-ellipsis group"}>
                {parsJson(diagnosticError?.message)
                  ? parsJson(diagnosticError?.message).replaceAll(/\n( *)/g, " \u21B5 ")
                  : diagnosticError?.message}
                {diagnosticError?.message && (
                  <div
                    className={
                      "overflow-auto hidden absolute -mt-10 z-10 p-4 bg-white group-hover:block rounded-lg border-bubl-green"
                    }
                    style={{
                      maxHeight: "250px",
                      borderWidth: "1px",
                      top: top,
                    }}>
                    <pre className="whitespace-pre-wrap">
                      <code>
                        {parsJson(diagnosticError?.message)
                          ? parsJson(diagnosticError?.message)
                          : diagnosticError?.message}
                      </code>
                    </pre>
                  </div>
                )}
              </td>
              <td className="px-6 py-4 flex justify-left flex-grow flex-wrap">
                {diagnosticError?.type && <Tag label={diagnosticError.type} type={"Gray"}></Tag>}
                {diagnosticError?.messageType && <Tag label={diagnosticError.messageType} type={"Gray"}></Tag>}
              </td>
              <td className="px-6 py-4 ">
                {diagnosticError?.date
                  ? new Date(diagnosticError.date).toLocaleString("sv-SE", {
                      dateStyle: "short",
                      timeStyle: "medium",
                    })
                  : ""}
              </td>
              <td>
                <div className="flex flex-row flex-nowrap justify-end items-center">
                  {userData?.user?.permissions.includes("ViewDiagnostic") && (
                    <div
                      className="px-6 py-4 flex flex-row"
                      onClick={() => {
                        showPopupVar({
                          chiled: <DiagnosticPopup diagnosticError={diagnosticError} />,
                        });
                      }}>
                      <IconInfo className="h-5 w-5 mr-2 cursor-pointer" title="Diagnostic Info" />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={6}>
            <div className="py-4 px-6 whitespace-nowrap"> No diagnostic found </div>
          </td>
        </tr>
      )}
      {allDiagnosticLoading && (
        <tr>
          <td colSpan={6}>
            <div className="m-6 flex justify-center">
              <Spinner />
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default TableRowDiagnostic;
