import React, { useEffect, useRef } from "react";

type Props = {
  open: boolean;
  setOpen: Function;
  childe: JSX.Element;
};

function FilterInput(props: Props) {
  const { childe, open, setOpen } = props;
  const popupRef = useRef(null);
  // Close popup when clicking outside of it
  function useOutsideAlerter(ref, func) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          func();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, func]);
  }
  useOutsideAlerter(popupRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  return (
    <div className="relative group flex" ref={popupRef}>
      {/* Dropdown filter*/}
      <div className=" absolute z-40 bg-white divide-y divide-gray-100 rounded-lg shadow w-max group-hover:block -top-1 right-0 max-h-80 overflow-y-auto ">
        <div className="px-4 py-3 text-sm text-gray-700">{childe}</div>
      </div>
    </div>
  );
}

export default FilterInput;
