import React from "react";
import { useDrop } from "react-dnd";
import { IconType } from "react-icons";
import { IoIosArrowUp as IconBack } from "react-icons/io";
import { ItemTypes } from "../Table/TableRowDevices";
import { useReactiveVar } from "@apollo/client";
import { activeInfo } from "../../cache";
type Props = {
  label: string;
  Icon: IconType;
  hasChildren: boolean;
  active: boolean;
  open?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  amount?: number;
  onClick: Function;
  accountId: String;
  divisionId: String;
  step?: number;
};

function IconHierarkiListRow(props: Props) {
  const { label, Icon, open, setOpen, amount, hasChildren, onClick, active, accountId, divisionId, step } = props;
  const info = useReactiveVar(activeInfo);
  function canDropRow(step: number) {
    if (step > 0) return true;
    return false;
  }
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.ROW,
      canDrop: () => canDropRow(step),
      drop: () => ({
        label,
        step,
        accountId,
        divisionId,
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [label, step, accountId, divisionId],
  );
  const isActive = canDrop && isOver;
  return (
    <div
      ref={drop}
      className={
        "flex flex-row w-full justify-between cursor-pointer items-center h-10 rounded-lg hover:bg-gray-100 " +
        (isActive ? "bg-bubl-green bg-opacity-40" : active ? "bg-gray-100" : "bg-white")
      }
      onClick={() => {
        onClick();
      }}>
      <div className="flex flex-row items-center w-10/12">
        <Icon style={{ fill: "rgb(17 24 39)" }} className="h-4 w-4 mx-2 shrink-0" />
        <span className="pt-0.5 text-sm font-bold text-gray-900 mx-3 whitespace-nowrap overflow-hidden text-ellipsis">
          {label}
        </span>
        {amount > 0 && (
          <div
            className=" px-2 bg-gray-500 text-white text-xs flex items-center h-4 "
            style={{ fontVariant: "all-small-caps" }}>
            {amount}
          </div>
        )}
      </div>
      <button className="mr-2 h-10 text-gray-900 outline-none focus:outline-none ">
        {hasChildren && (
          <IconBack
            style={{ fill: "rgb(17 24 39)" }}
            className={`h-4 w-4 transform transition-all ${open ? "" : "-rotate-180"} `}
            onClick={() => {
              setOpen(!open);
            }}
          />
        )}
      </button>
    </div>
  );
}

export default IconHierarkiListRow;
