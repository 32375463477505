import React, { useEffect, useState } from "react";
import SelectInput from "./SelectInput";
import { GoEye as IconEye } from "react-icons/go";
import { GoEyeClosed as IconEyeClose } from "react-icons/go";
import { MdDelete as IconDelete } from "react-icons/md";
import { MdInfo as IconInfo } from "react-icons/md";
import CheckboxHierarkiList from "../checkboxHierarkeList/CheckboxHierarkiList";
import {
  Division,
  GetDivisionsbyAccountQuery,
  GetDivisionsbyAccountQueryVariables,
  GetRolesQuery,
  GetRolesQueryVariables,
  Role,
} from "../../graphql/graphqlTypes";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_DIVISIONS_BY_ACCOUNT, GET_ROLES } from "../../graphql/queries";
import IconButton from "../button/IconButton";
import { IoMdAddCircle as IconAdd } from "react-icons/io";
import { activeInfo, showPopupVar } from "../../cache";
import { findChildeDivision } from "../../utils";
import Tooltip from "./Tooltip";
type Props = {
  selectedRoles: Role[];
  setSelectedRoles: React.Dispatch<React.SetStateAction<Role[]>>;
};
function RoleList({ selectedRoles, setSelectedRoles }: Props) {
  const active = useReactiveVar(activeInfo);
  const { data: userRolesData } = useQuery<GetRolesQuery, GetRolesQueryVariables>(GET_ROLES);
  const { data: divisionData } = useQuery<GetDivisionsbyAccountQuery, GetDivisionsbyAccountQueryVariables>(
    GET_DIVISIONS_BY_ACCOUNT,
    { variables: { accountId: active.account.id } },
  );
  const showPopup = useReactiveVar(showPopupVar);
  const [filterdRoles, setFilterdRoles] = useState([]);
  // Remove choosen rooles from selectList
  useEffect(() => {
    if (userRolesData?.roles) {
      const filter = userRolesData.roles.filter((role) => {
        let keep = true;
        selectedRoles?.forEach((selectedRole) => {
          if (selectedRole.id === role.id) keep = false;
        });
        return keep;
      });
      setFilterdRoles(filter);
    }
  }, [userRolesData, selectedRoles]);

  function handelingRoles(type: string, role: Role) {
    showPopupVar({
      ...showPopup,
      outsideSafety: true,
    });
    let roleExist = selectedRoles.find((selected) => selected.id === role.id);
    if (type === "ADD" && !roleExist) {
      setSelectedRoles(selectedRoles.concat(role));
    } else if (type === "DELETE") {
      const filtered = selectedRoles.filter((selected) => {
        return selected.id !== role.id;
      });
      setSelectedRoles(filtered);
    } else if (type === "UPDATE") {
      const filtered = selectedRoles?.map((selected) => {
        if (selected.id === role.id) {
          return role;
        } else if (selected.id !== "") {
          return selected;
        } else {
          return role;
        }
      });
      setSelectedRoles(filtered);
    }
  }
  return (
    <>
      <div className="flex flex-row">
        <label htmlFor="default-input" className="block mb-2 text-sm font-bold text-gray-900 mr-2">
          Roles
        </label>
        <Tooltip
          label={"Add roles to the user for access control and permissions in selected groups"}
          left={"22px"}
          top={"0px"}
          bottom={"0px"}
          childern={<IconInfo className="h-5 w-5 mr-2 cursor-pointer" />}
        />
      </div>
      {selectedRoles?.map((role, i) => {
        return (
          <CheckboxList
            key={i}
            userRolesData={filterdRoles}
            initRole={role}
            divisionData={divisionData && divisionData.getDivisionsbyAccount}
            onChange={(type, value) => handelingRoles(type, value)}
          />
        );
      })}
      <div className="-ml-2 mb-6">
        <IconButton
          label={"Add role"}
          Icon={IconAdd}
          onClick={() => handelingRoles("ADD", { id: "", name: "", divisions: [] })}
        />
      </div>
    </>
  );
}

type CheckboxListProps = {
  userRolesData: Array<Role>;
  divisionData: Array<Division>;
  initRole: Role;
  onChange: Function;
};
function CheckboxList({ userRolesData, divisionData, initRole, onChange }: CheckboxListProps) {
  const [open, setOpen] = useState(false);
  const [activeDivisionsId, setActiveDivisionId] = useState([]);

  //Handel change of division for a userrole
  function onClick(div: Division) {
    if (initRole?.divisions?.filter((division) => division.id === div.id).length > 0) {
      //Remove it from the list
      onChange("UPDATE", { ...initRole, divisions: initRole.divisions.filter((division) => division.id !== div.id) });
    } else {
      let newDivisions = [];
      //Add it to the list
      if (activeDivisionsId.includes(div.id)) {
        //Remove Parent
        initRole.divisions.forEach((division) => {
          const childrenId = findChildeDivision(division).map((e) => {
            return e.id;
          });
          if (!childrenId.includes(div.id)) newDivisions.push(division);
        });
      } else {
        //Remove childeren
        const childrenId = findChildeDivision(div).map((e) => {
          return e.id;
        });
        newDivisions = initRole.divisions.filter((division) => !childrenId.includes(division.id));
      }
      newDivisions.push(div);
      onChange("UPDATE", { ...initRole, divisions: newDivisions });
    }
  }

  useEffect(() => {
    if (initRole?.divisions) {
      let activeDivisionsCreate = [];
      initRole.divisions.forEach((division) => {
        findChildeDivision(division).forEach((e) => {
          activeDivisionsCreate.push(e.id);
        });
      });
      setActiveDivisionId(activeDivisionsCreate);
    }
  }, [initRole, initRole.divisions]);
  return (
    <div className="w-full mb-1">
      <div className="w-full flex flex-row justify-center items-center ">
        <div className="flex-grow">
          {divisionData && (
            <SelectInput
              multiple={false}
              disabled={initRole.name !== ""}
              initValue={initRole}
              items={userRolesData}
              onChange={(value) => {
                if (initRole.id !== value[0].id) {
                  if (value[0].name === "UserAdmin") {
                    // For UserAdmin role - automatically set user role division to top "account division"
                    onChange("UPDATE", { ...value[0], divisions: [divisionData[0]] }); //Update name och id
                  } else {
                    onChange("UPDATE", { ...value[0], divisions: [] }); //Update name och id
                  }

                  if (value[0].name !== "SuperAdmin" && value[0].name !== "UserAdmin") setOpen(true);
                }
              }}
            />
          )}
        </div>
        {open ? (
          <Tooltip
            label={"Hide role groups"}
            right={"-40px"}
            childern={
              <IconEye
                className="h-4 w-4 ml-4 mr-2 cursor-pointer"
                onClick={() => {
                  setOpen(false);
                }}
              />
            }></Tooltip>
        ) : (
          <>
            {initRole.name !== "" && initRole.name !== "SuperAdmin" && initRole.name !== "UserAdmin" ? (
              <Tooltip
                label={"Show role groups"}
                right={"-40px"}
                childern={
                  <IconEyeClose
                    className="h-4 w-4 ml-4 mr-2 cursor-pointer "
                    style={{
                      opacity:
                        initRole.name === "" || initRole.name === "SuperAdmin" || initRole.name === "UserAdmin"
                          ? "30%"
                          : "100%",
                    }}
                    onClick={() => {
                      if (initRole.name !== "" && initRole.name !== "SuperAdmin" && initRole.name !== "UserAdmin")
                        setOpen(true);
                    }}
                  />
                }></Tooltip>
            ) : (
              <div className="h-4 w-4 ml-4 mr-2 "></div>
            )}
          </>
        )}
        <Tooltip
          label={"Delete role"}
          right={"-25px"}
          childern={
            <IconDelete
              className="h-4 w-4  mr-2 cursor-pointer"
              onClick={() => {
                onChange("DELETE", initRole);
              }}
            />
          }></Tooltip>
      </div>
      {initRole && open && (
        <div className="bg-gray-100 rounded-b-lg overflow-x-auto h-40 mr-16 ">
          {divisionData?.map((division, i) => {
            return (
              <CheckboxHierarkiList
                key={division.id + i}
                activeDivisions={activeDivisionsId}
                list={division}
                step={0}
                onClick={(div: Division) => {
                  onClick(div);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default RoleList;
