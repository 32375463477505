import React from "react";

type Props = {
  id: string;
  label: string;
  value: string;
  onChange: Function;
  onKeyDown?: Function;
  type: "email" | "password" | "text";
  disable?: boolean;
};

function TextInput(props: Props) {
  const { id, label, onChange, onKeyDown, value, type, disable } = props;
  return (
    <div className="mb-6">
      <label htmlFor="default-input" className="block mb-2 text-sm font-bold text-gray-900 ">
        {label}
      </label>
      <input
        type={type}
        disabled={disable}
        id={"default-input" + id}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (onKeyDown) onKeyDown(e);
        }}
        value={value}
        className="bg-gray-100 border border-gray-300 text-gray-900 focus:border-bubl-green outline-none text-sm rounded-lg block w-full p-2.5 disabled:text-opacity-50"
      />
    </div>
  );
}

export default TextInput;
