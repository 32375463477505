import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../components/Menu/Navbar";
import { GetUserQuery, GetUserQueryVariables } from "../graphql/graphqlTypes";
import { GET_USER } from "../graphql/queries";

function Home() {
  const { data: userData, loading: userDataLoading } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER);
  let history = useHistory();

  useEffect(() => {
    if (userData) {
      // Dashboard is disabled for now - therefore redirect user based on permission
      if (userData.user.permissions.includes("ViewDevice")) {
        history.push("/iot/devices");
      } else if (userData.user.permissions.includes("ViewDiagnostic")) {
        history.push("/iot/diagnostic");
      } else if (userData.user.permissions.includes("ViewFirmware")) {
        history.push("/iot/firmware");
      } else if (userData.user.permissions.includes("ViewUser")) {
        history.push("/admin");
      }
    }
  }, [userData]);

  return (
    <div className="w-full h-full bg-gray-100 flex flex-col">
      <Navbar />
      {/* <div className="flex flex-row h-full overflow-y-auto">
        <div className="m-8 rounded-xl border bg-white flex-grow p-8 flex flex-col justify-between">
          <div className="overflow-auto">
            <div className="text-2xl font-bold mt-2 mb-2 w-full ">Welcome to bubl. Cloud</div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Home;
