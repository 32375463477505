import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  DeleteAccountMutation,
  DeleteAccountMutationVariables,
  DeleteDivisionMutation,
  DeleteDivisionMutationVariables,
  GetAllDivisionUserRolesQuery,
  GetAllDivisionUserRolesQueryVariables,
  GetDivisionsQuery,
  GetDivisionsQueryVariables,
  GetDivisionUserRolesQuery,
  GetDivisionUserRolesQueryVariables,
  GetRolesQuery,
  GetRolesQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
  UpdateDivisionMutation,
  UpdateDivisionMutationVariables,
} from "../graphql/graphqlTypes";
import {
  GET_ALL_DIVISION_USER_ROLES,
  GET_DIVISIONS,
  GET_DIVISION_USER_ROLES,
  GET_ROLES,
  GET_USER,
} from "../graphql/queries";
import {
  activeInfo,
  adminFilterVar,
  setActive,
  showConfirmDialogVar,
  showPopupVar,
  userRolesFilterVar,
} from "../cache";
import Button from "../components/button/Button";
import IconButton from "../components/button/IconButton";
import Navbar from "../components/Menu/Navbar";
import Sidebar from "../components/Menu/Sidebar";
import Table from "../components/Table/Table";
import TableRowAccountUser from "../components/Table/TableRowAccountUser";
import UserPopup from "../components/popups/UserPopup";
import TableRowUserRoles from "../components/Table/TableRowUserRoles";
import UserRolePopup from "../components/popups/UserRolePopup";
import { FaSave as IconSave, FaUserCircle } from "react-icons/fa";
import { MdEdit as IconEdit } from "react-icons/md";
import { MdDelete as IconDelete } from "react-icons/md";
import { FaEye as IconEye } from "react-icons/fa";
import { IoFilterSharp as IconFilter } from "react-icons/io5";
import { DELETE_ACCOUNT, DELETE_DIVISION, UPDATE_ACCOUNT, UPDATE_DIVISION } from "../graphql/mutations";
import FilterInput from "../components/inputs/FilterInput";
import Checkbox from "../components/inputs/Checkbox";
import PremissionPopup from "../components/popups/PremissionPopup";
import Tooltip from "../components/inputs/Tooltip";
import { allRoles } from "../utils";

function Admin() {
  const [openFilter, setOpenFilter] = useState(false);
  const [editName, setEditName] = useState(false);
  const [name, setName] = useState("");
  const nameInput = useRef(null);
  const adminFilter = useReactiveVar(adminFilterVar);
  const userRolesFilter = useReactiveVar(userRolesFilterVar);

  //Refetching data after update
  const active = useReactiveVar(activeInfo);
  const { refetch: divisionUserRolesRefetch } = useQuery<GetDivisionUserRolesQuery, GetDivisionUserRolesQueryVariables>(
    GET_DIVISION_USER_ROLES,
    {
      variables: {
        divisionId: active?.division?.id,
        roles: adminFilter.roles,
        sort: userRolesFilter.sort,
        order: userRolesFilter.order,
      },
      fetchPolicy: "no-cache",
      skip: !active?.division?.id,
    },
  );
  const { refetch: allDivisionUserRolesRefetch } = useQuery<
    GetAllDivisionUserRolesQuery,
    GetAllDivisionUserRolesQueryVariables
  >(GET_ALL_DIVISION_USER_ROLES, {
    fetchPolicy: "no-cache",
    variables: {
      accountId: active?.account?.id,
      roles: adminFilter.roles,
      sort: adminFilter.sort,
      order: adminFilter.order,
    },
    skip: !active?.account?.id,
  });
  const { refetch: divisionRefetch } = useQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(GET_DIVISIONS);
  useEffect(() => {
    if (active?.division?.name) {
      setName(active.division.name);
    }
  }, [active]);
  const [updateAccount] = useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UPDATE_ACCOUNT);
  const [deleteAccount] = useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DELETE_ACCOUNT);
  const [updateDivision] = useMutation<UpdateDivisionMutation, UpdateDivisionMutationVariables>(UPDATE_DIVISION);
  const [deleteDivision] = useMutation<DeleteDivisionMutation, DeleteDivisionMutationVariables>(DELETE_DIVISION);

  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  function handelFilter(role: string) {
    if (adminFilter.roles && adminFilter.roles.includes(role)) {
      adminFilterVar({
        ...adminFilter,
        roles: adminFilter.roles.filter((r) => {
          return r !== role;
        }),
      });
    } else {
      adminFilterVar({
        ...adminFilter,
        roles: adminFilter.roles ? [...adminFilter.roles, role] : [role],
      });
    }
  }
  if (!userData?.user?.permissions) return <></>;
  return (
    <div className="w-full h-full bg-gray-100 flex flex-col">
      <Navbar />
      <div className="flex flex-row h-full overflow-y-auto">
        {/* Sidebar */}
        <Sidebar />
        {/* Content  */}
        <div
          className="m-8 rounded-xl border bg-white flex-grow p-8 flex flex-col  "
          style={{ width: "calc(100% - 20rem)" }}>
          <div className="flex justify-between mb-4">
            <div>
              <div className="flex flex-row">
                <div
                  className="text-lg font-bold mt-2 mb-2 whitespace-nowrap overflow-hidden text-ellipsis"
                  onClick={() => setEditName(true)}>
                  {editName ? (
                    <input
                      ref={nameInput}
                      className="w-full focus:border-bubl-green outline-none "
                      type="text"
                      value={name}
                      autoFocus={true}
                      onChange={(value) => setName(value.target.value)}
                      onBlur={() => {
                        if (name !== active.division.name) {
                          showConfirmDialogVar({
                            title: "Unsaved changes",
                            message: "Are you sure?",
                            confirmText: "Discard",
                            saveText: "Save",
                            onConfirm: () => {
                              setName(active.division.name);
                              showConfirmDialogVar(null);
                              setEditName(false);
                            },
                            onSave: async () => {
                              if (active?.division?.account?.id) {
                                await updateAccount({
                                  variables: {
                                    accountId: active.account.id,
                                    name: name,
                                  },
                                });
                              } else {
                                await updateDivision({
                                  variables: {
                                    divisionId: active.division.id,
                                    name: name,
                                  },
                                });
                              }
                              divisionRefetch();
                              setEditName(false);
                              showConfirmDialogVar(null);
                              activeInfo({ ...active, division: { ...active.division, name: name } });
                              setActive({ ...active, division: { ...active.division, name: name } });
                            },
                            onCancel: () => {
                              if (nameInput?.current) nameInput.current.focus();
                              showConfirmDialogVar(null);
                            },
                          });
                        } else {
                          setEditName(false);
                        }
                      }}
                    />
                  ) : active && active?.division?.name !== "Accounts" ? (
                    active.division.name
                  ) : (
                    "Choose an Account or Group"
                  )}
                </div>
                <div className="flex flex-row flex-nowrap justify-end items-center">
                  {((active?.division?.account?.id && userData?.user?.permissions.includes("EditAccount")) ||
                    (active?.division?.id && userData?.user?.permissions.includes("EditDivision"))) && (
                    <div
                      className="px-2 flex flex-row"
                      onMouseDown={async (e) => {
                        e.preventDefault();
                        if (editName) {
                          if (active?.division?.account?.id) {
                            await updateAccount({
                              variables: {
                                accountId: active.account.id,
                                name: name,
                              },
                            });
                          } else {
                            await updateDivision({
                              variables: {
                                divisionId: active.division.id,
                                name: name,
                              },
                            });
                          }
                          divisionRefetch();
                          activeInfo({ ...active, division: { ...active.division, name: name } });
                          setActive({ ...active, division: { ...active.division, name: name } });
                        }
                        setEditName(!editName);
                      }}>
                      {editName ? (
                        <IconSave
                          className="h-5 w-5 ml-4 cursor-pointer"
                          title={active?.division?.account?.id ? "Save Account" : "Save Group"}
                        />
                      ) : (
                        <IconEdit
                          className="h-5 w-5 ml-4 cursor-pointer"
                          title={active?.division?.account?.id ? "Edit Account" : "Edit Group"}
                        />
                      )}
                    </div>
                  )}
                  {((active?.division?.account?.id && userData?.user?.permissions.includes("DeleteAccount")) ||
                    (active?.division?.id && userData?.user?.permissions.includes("DeleteDivision"))) && (
                    <div
                      title={active?.division?.account?.id ? "Delete Account" : "Delete Group"}
                      onClick={() => {
                        showConfirmDialogVar({
                          title: active?.division?.account?.id ? "Delete Account" : "Delete Group",
                          message: "Are you sure?",
                          confirmText: "Delete",
                          onConfirm: async () => {
                            showConfirmDialogVar(null);
                            if (active?.division?.account?.id) {
                              await deleteAccount({
                                variables: {
                                  accountId: active.account.id,
                                },
                              }).then((e) => {
                                if (e?.data?.deleteAccount?.error) {
                                  const errorMsg = e.data.deleteAccount.error.msg.split("|");
                                  showConfirmDialogVar({
                                    title: errorMsg[0],
                                    message: errorMsg[1] + " \n before deleting the Account",
                                    confirmText: "Ok",
                                    onConfirm: async () => {
                                      showConfirmDialogVar(null);
                                    },
                                  });
                                }
                                if (e?.data.deleteAccount.success) {
                                  activeInfo(null);
                                  setActive(null);
                                }
                              });
                            } else {
                              await deleteDivision({
                                variables: {
                                  divisionId: active.division.id,
                                },
                              }).then((e) => {
                                if (e?.data?.deleteDivision?.error) {
                                  const errorMsg = e.data.deleteDivision.error.msg.split("|");
                                  showConfirmDialogVar({
                                    title: errorMsg[0],
                                    message: errorMsg[1] + "\n before deleting the Group",
                                    confirmText: "Ok",
                                    onConfirm: async () => {
                                      showConfirmDialogVar(null);
                                    },
                                  });
                                }
                                if (e?.data.deleteDivision.success) {
                                  activeInfo(null);
                                  setActive(null);
                                }
                              });
                            }
                            divisionRefetch();
                          },
                          onCancel: () => {
                            showConfirmDialogVar(null);
                          },
                        });
                      }}>
                      <IconDelete className="h-5 w-5  cursor-pointer" />
                    </div>
                  )}
                </div>
              </div>
              {active && active.division.name !== "Accounts" && (
                <span className="text-xs text-black text-opacity-60" style={{ letterSpacing: "0.02rem" }}>
                  {active?.division?.account?.id
                    ? "This is a list of users for the selected account"
                    : "This is a list of user roles for the selected group"}
                </span>
              )}
            </div>
            {active?.division?.name &&
              active.division.name !== "Accounts" &&
              userData.user.permissions.includes("ViewUser") && (
                <div className="flex flex-row">
                  <div>
                    {/*  Premission Table */}
                    {/* <IconButton
                      label={"Permission"}
                      Icon={IconEye}
                      onClick={() => {
                        showPopupVar({
                          chiled: <PremissionPopup />,
                        });
                      }}
                    /> */}
                  </div>
                  <div>
                    {/*  Filter */}
                    <IconButton
                      label={"Filter"}
                      Icon={IconFilter}
                      onClick={() => {
                        setOpenFilter(!openFilter);
                      }}
                    />
                    {openFilter && (
                      <FilterInput
                        open={openFilter}
                        setOpen={setOpenFilter}
                        childe={
                          <div>
                            <div className="text-md font-bold   mb-2">Roles</div>
                            <>
                              <Checkbox
                                id={"checkbox_All"}
                                label={"All"}
                                checked={allRoles.length === adminFilter?.roles?.length}
                                onChange={(e) => {
                                  if (allRoles.length === adminFilter?.roles?.length) {
                                    adminFilterVar({
                                      ...adminFilter,
                                      roles: [],
                                    });
                                  } else {
                                    adminFilterVar({
                                      ...adminFilter,
                                      roles: allRoles.map((role) => {
                                        return role;
                                      }),
                                    });
                                  }
                                }}
                              />
                              {allRoles.map((role, i) => {
                                return (
                                  <div key={i}>
                                    <Checkbox
                                      id={"checkbox_" + role}
                                      label={role}
                                      checked={adminFilter?.roles?.includes(role)}
                                      onChange={() => handelFilter(role)}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          </div>
                        }
                      />
                    )}
                  </div>
                </div>
              )}
          </div>
          {active?.division?.account?.id ? (
            <>
              <div className="flex flex-row items-center text-base font-bold mt-2 mb-2">
                <FaUserCircle className=" text-md mr-2" />
                Users
              </div>
              <div className="my-6 overflow-auto">
                <Table
                  headers={["Name", "Email", "Roles", "Last Logged In", ""]}
                  Row={<TableRowAccountUser />}
                  sort={(name) => {
                    if (adminFilter.sort === name) {
                      adminFilterVar({ ...adminFilter, order: !adminFilter.order });
                    } else {
                      adminFilterVar({ ...adminFilter, sort: name });
                    }
                  }}
                  activeSort={adminFilter.sort}
                  order={adminFilter.order}
                />
              </div>
              {userData.user.permissions.includes("CreateUser") && (
                <div>
                  <Button
                    label={"New User"}
                    disable={false}
                    onClick={() => {
                      showPopupVar({
                        chiled: (
                          <UserPopup
                            refetch={() => {
                              allDivisionUserRolesRefetch();
                              adminFilterVar({ ...adminFilter, order: !adminFilter.order });
                              adminFilterVar({ ...adminFilter, order: !adminFilter.order });
                              userRolesFilterVar({ ...userRolesFilter, order: !userRolesFilter.order });
                              userRolesFilterVar({ ...userRolesFilter, order: !userRolesFilter.order });
                            }}
                          />
                        ),
                      });
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            active?.division?.id && (
              <>
                <div className="text-base font-bold mt-2 mb-2 flex items-end">User roles</div>
                <div className="my-6 overflow-auto">
                  <Table
                    headers={["Name", "Roles", ""]}
                    Row={<TableRowUserRoles />}
                    sort={(name) => {
                      if (userRolesFilter.sort === name) {
                        userRolesFilterVar({ ...userRolesFilter, order: !userRolesFilter.order });
                      } else {
                        userRolesFilterVar({ ...userRolesFilter, sort: name });
                      }
                    }}
                    activeSort={userRolesFilter.sort}
                    order={userRolesFilter.order}
                  />
                </div>
                {userData.user.permissions.includes("EditUser") && (
                  <div>
                    <Button
                      label={"New User Role"}
                      disable={false}
                      onClick={() => {
                        showPopupVar({
                          chiled: (
                            <UserRolePopup
                              refetch={() => {
                                divisionUserRolesRefetch();
                                userRolesFilterVar({ ...userRolesFilter, order: !userRolesFilter.order });
                                userRolesFilterVar({ ...userRolesFilter, order: !userRolesFilter.order });
                              }}
                            />
                          ),
                        });
                      }}
                    />
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Admin;
