import React, { MouseEventHandler } from "react";

type Props = {
  label: string;
  disable: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

function Button(props: Props) {
  const { label, onClick, disable } = props;
  return (
    <button
      className="border py-3 px-12 m-2 rounded-lg inline-block text-md text-center font-bold bg-bubl-green text-white cursor-pointer hover:bg-opacity-75  transform transition-all active:scale-95 disabled:opacity-50 disabled:cursor-auto disabled:active:scale-100"
      style={{ letterSpacing: "0.12em", fontVariant: "all-small-caps" }}
      disabled={disable}
      onClick={onClick}>
      {label}
    </button>
  );
}

export default Button;
