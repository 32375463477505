import React, { useCallback, useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  AllDiagnosticsQuery,
  AllDiagnosticsQueryVariables,
  CountAllDiagnosticsQuery,
  CountAllDiagnosticsQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
} from "../graphql/graphqlTypes";
import { GET_ALL_DIAGNOSTIC, GET_COUNT_ALL_DIAGNOSTIC, GET_USER } from "../graphql/queries";
import { activeInfo, diagnosticFilterVar } from "../cache";
import IconButton from "../components/button/IconButton";
import Navbar from "../components/Menu/Navbar";
import Table from "../components/Table/Table";
import { IoFilterSharp as IconFilter } from "react-icons/io5";
import TableRowDiagnostic from "../components/Table/TableRowDiagnostics";
import SearchInput from "../components/inputs/SearchInput";
import FilterInput from "../components/inputs/FilterInput";
import Checkbox from "../components/inputs/Checkbox";
import Button from "../components/button/Button";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Pagination from "../components/Pagination";
import { IoMdSearch as IconSearch, IoIosArrowUp as IconBack } from "react-icons/io";
import { allDiagnosticsEventTypes } from "../utils";

const typeRelations = {
  Event: [
    "Error",
    "Setup",
    "SetAirQualityIndex",
    "StartListening",
    "StopListening",
    "SetDetectLocationEnabled",
    "DeviceConnect",
    "DeviceConnected",
    "DeviceDisconnect",
    "DeviceDisconnected",
    "DeviceReconnect",
    "DeviceConnectError",
    "SetPower",
    "SetSensors",
    "SetFanSpeed",
    "FilterChanged",
    "PoweredOn",
    "PoweredOff",
    "SensorsOn",
    "SensorsOff",
    "FanSpeedChanged",
    "FilterRuntimeChanged",
    "BatteryLevelChanged",
    "DeviceErrorsChanged",
    "Location",
    "ParticleSensor",
    "TemperatureSensor",
    "HumiditySensor",
  ],
  FirmwareUpdate: [],
  InsideReading: ["inside"],
  Reading: ["initial", "spot"],
};

function Diagnostic() {
  const [openFilter, setOpenFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  //Refetching data after update
  const active = useReactiveVar(activeInfo);
  const diagnosticFilter = useReactiveVar(diagnosticFilterVar);
  const { data: countAllDiagnostic } = useQuery<CountAllDiagnosticsQuery, CountAllDiagnosticsQueryVariables>(
    GET_COUNT_ALL_DIAGNOSTIC,
    {
      variables: {
        types: diagnosticFilter.types,
        searchText: diagnosticFilter.searchText,
      },
    },
  );
  const { data: allDiagnostic, refetch: refetchAllDiagnostic } = useQuery<
    AllDiagnosticsQuery,
    AllDiagnosticsQueryVariables
  >(GET_ALL_DIAGNOSTIC, {
    variables: {
      types: diagnosticFilter.types,
      searchText: diagnosticFilter.searchText,
      sort: diagnosticFilter.sort,
      order: diagnosticFilter.order,
      take: diagnosticFilter.take,
      skip: diagnosticFilter.skip,
    },
    fetchPolicy: "network-only",
  });
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  function handelFilter(type: string) {
    const isParentType = Object.keys(typeRelations).includes(type);
    let parent = null;
    Object.keys(typeRelations).forEach((currentParent) => {
      if (typeRelations[currentParent].includes(type)) {
        parent = currentParent;
      }
    });
    if (diagnosticFilter.types.includes(type)) {
      // Toggle off
      if (isParentType) {
        // If it is a parent type => toggle off all child types as well
        diagnosticFilterVar({
          ...diagnosticFilter,
          skip: 0,
          types: diagnosticFilter.types.filter((t) => {
            return !typeRelations[type].includes(t) && t !== type;
          }),
        });
      } else {
        diagnosticFilterVar({
          ...diagnosticFilter,
          skip: 0,
          types: diagnosticFilter.types.filter((t) => {
            return t !== type && t !== parent;
          }),
        });
      }
    } else {
      // Toggle on
      if (isParentType) {
        // If it is a parent type => toggle on all child types as well
        diagnosticFilterVar({
          ...diagnosticFilter,
          types: [...diagnosticFilter.types, type, ...typeRelations[type]],
          skip: 0,
        });
      } else {
        diagnosticFilterVar({
          ...diagnosticFilter,
          types: [...diagnosticFilter.types, type],
          skip: 0,
        });
      }
    }
  }

  function getFilterCheckboxes() {
    return Object.keys(typeRelations).map((parentType) => {
      return (
        <FilterCheckbox
          key={parentType}
          parentType={parentType}
          diagnosticFilter={diagnosticFilter}
          handelFilter={handelFilter}
        />
      );
    });
  }

  useEffect(() => {
    if (!openFilter && diagnosticFilter.types.length < 1) {
      diagnosticFilterVar({
        ...diagnosticFilter,
        types: allDiagnosticsEventTypes,
      });
    }
  }, [openFilter]);

  const onSearch = useCallback(
    (value: string) => diagnosticFilterVar({ ...diagnosticFilter, searchText: value }),
    [diagnosticFilter],
  );

  if (!userData?.user?.permissions) return <></>;
  return (
    <div className="w-full h-full bg-gray-100 flex flex-col">
      <Navbar />
      <div className="flex flex-row h-full overflow-y-auto">
        {/* Content  */}
        <div
          className="m-8 rounded-xl border bg-white flex-grow p-8 flex flex-col "
          style={{ width: "calc(100% - 4rem)" }}>
          <div className="flex justify-between mb-4">
            <div>
              <div className="flex flex-row items-center">
                {searchText !== "" && <IconSearch className="h-6 w-6  cursor-pointer text-gray-600 mr-2" />}
                <div className="text-lg font-bold mt-2 mb-2 ">Diagnostic </div>{" "}
                {searchText !== "" && (
                  <div className="ml-12">
                    <IconButton
                      label={"Exit search"}
                      onClick={() => {
                        setSearchText("");
                        diagnosticFilterVar({ ...diagnosticFilter, searchText: "" });
                      }}
                    />
                  </div>
                )}
              </div>
              <span className="text-xs text-black text-opacity-60" style={{ letterSpacing: "0.02rem" }}>
                {searchText !== "" ? "Searching in Diagnostic." : "Diagnostic messages list."}
              </span>
            </div>
            {userData.user.permissions.includes("ViewDiagnostic") && (
              <div className="flex flex-row">
                <SearchInput
                  value={searchText}
                  onChange={(value) => {
                    setSearchText(value);
                  }}
                  onSearch={onSearch}
                />
                <div>
                  <IconButton
                    label={"Filter"}
                    Icon={IconFilter}
                    onClick={() => {
                      setOpenFilter(!openFilter);
                    }}
                  />
                  {openFilter && (
                    <FilterInput
                      open={openFilter}
                      setOpen={setOpenFilter}
                      childe={
                        <div>
                          <div className="text-md font-bold mb-2">Types</div>
                          <div>
                            <Checkbox
                              id={"checkbox_All"}
                              label={"All"}
                              checked={diagnosticFilter.types.length === allDiagnosticsEventTypes.length}
                              onChange={(e) => {
                                if (diagnosticFilter.types.length === allDiagnosticsEventTypes.length) {
                                  diagnosticFilterVar({
                                    ...diagnosticFilter,
                                    types: [],
                                  });
                                } else {
                                  diagnosticFilterVar({
                                    ...diagnosticFilter,
                                    types: allDiagnosticsEventTypes,
                                  });
                                }
                              }}
                            />
                          </div>
                          {getFilterCheckboxes()}
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="my-2 overflow-auto">
            <Table
              headers={["Device", "Message", "Type", "Logged at", ""]}
              Row={<TableRowDiagnostic />}
              sort={(name) => {
                if (diagnosticFilter.sort === name) {
                  diagnosticFilterVar({ ...diagnosticFilter, order: !diagnosticFilter.order });
                } else {
                  diagnosticFilterVar({ ...diagnosticFilter, sort: name });
                }
              }}
              activeSort={diagnosticFilter.sort}
              order={diagnosticFilter.order}
            />
          </div>
          <div className="w-full flex flex-row justify-between">
            {userData.user.permissions.includes("ViewDiagnostic") && (
              <div>
                <Button
                  label={"Export Table"}
                  disable={false}
                  onClick={async () => {
                    const allDiagnosticForDownload = await refetchAllDiagnostic({
                      types: diagnosticFilter.types,
                      searchText: diagnosticFilter.searchText,
                      sort: diagnosticFilter.sort,
                      order: diagnosticFilter.order,
                      take: -1,
                      skip: 0,
                    });
                    //Create workbook
                    const workbook = new ExcelJS.Workbook();
                    workbook.creator = userData.user.name;
                    workbook.lastModifiedBy = userData.user.name;
                    workbook.created = new Date();
                    workbook.modified = new Date();

                    //Create Diagnostic Sheet
                    const diagnosticSheet = workbook.addWorksheet("Diagnostic", {
                      properties: { tabColor: { argb: "82DBC2" } },
                      // views: [{ state: "frozen", xSplit: 1 }],
                    });
                    //Add header to Diagnostic Sheet
                    diagnosticSheet.columns = [
                      { header: "Device", key: "macAddress", width: 20 },
                      { header: "Type", key: "type", width: 20 },
                      { header: "Logged at", key: "date", width: 20 },
                      { header: "Message", key: "message", width: 100 },
                    ];
                    //Fill sheet with data
                    allDiagnosticForDownload?.data?.allDiagnostics?.forEach((diagnosticRow) => {
                      diagnosticSheet.addRow({
                        ...diagnosticRow,
                        macAddress: diagnosticRow.macAddress ? diagnosticRow.macAddress : diagnosticRow.initId,
                        date: new Date(diagnosticRow.date).toLocaleString("sv-SE", {
                          dateStyle: "short",
                          timeStyle: "medium",
                        }),
                      });
                    });
                    try {
                      //Create document and download
                      const buffer = await workbook.xlsx.writeBuffer();
                      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                      const fileName =
                        "Export-" +
                        new Date().toLocaleString("sv-SE", {
                          dateStyle: "short",
                          timeStyle: "medium",
                        }) +
                        ".xlsx";

                      const blob = new Blob([buffer], { type: fileType });
                      saveAs(blob, fileName);
                    } catch (error) {
                      console.log(error);
                    }
                    await refetchAllDiagnostic({
                      types: diagnosticFilter.types,
                      searchText: diagnosticFilter.searchText,
                      sort: diagnosticFilter.sort,
                      order: diagnosticFilter.order,
                      take: diagnosticFilter.take,
                      skip: diagnosticFilter.skip,
                    });
                  }}
                />
              </div>
            )}
            <Pagination
              totalPages={
                countAllDiagnostic?.countAllDiagnostics
                  ? Math.ceil(countAllDiagnostic.countAllDiagnostics / diagnosticFilter.take)
                  : 1
              }
              active={diagnosticFilter.skip / diagnosticFilter.take + 1}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const FilterCheckbox = ({ parentType, diagnosticFilter, handelFilter }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div
        className={`flex justify-between items-center my-2 ${
          typeRelations[parentType].length > 0 ? "cursor-pointer" : ""
        }`}
        onClick={() => {
          setExpanded(!expanded);
        }}>
        {/* Parent type checkbox */}
        <Checkbox
          id={"checkbox_" + parentType}
          label={parentType}
          checked={diagnosticFilter.types.includes(parentType)}
          onChange={(e) => handelFilter(parentType)}
        />
        {typeRelations[parentType].length > 0 ? (
          <IconBack
            style={{ fill: "rgb(17 24 39)" }}
            size={16}
            className={`transform transition-all ${expanded ? "" : "-rotate-180"} `}
          />
        ) : (
          <div className="w-4 h-4"></div>
        )}
      </div>
      {expanded && (
        <div onClick={(e) => e.stopPropagation()}>
          {/* Child types checkboxes */}
          {typeRelations[parentType].map((childType) => {
            return (
              <div key={childType} className="ml-2 my-2">
                <Checkbox
                  id={"checkbox_" + childType}
                  label={childType}
                  checked={diagnosticFilter.types.includes(childType)}
                  onChange={(e) => handelFilter(childType)}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Diagnostic;
