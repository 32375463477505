import { useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { IoFilterSharp as IconFilter } from "react-icons/io5";
import {
  firmwareFilterRolloutVar,
  firmwareFilterVar,
  firmwareRolloutSortVar,
  firmwareSortVar,
  showPopupVar,
} from "../cache";
import Navbar from "../components/Menu/Navbar";
import Table from "../components/Table/Table";
import TableRowFirmware from "../components/Table/TableRowFirmware";
import TableRowFirmreRollouts from "../components/Table/TableRowFirmwareRollouts";
import Button from "../components/button/Button";
import IconButton from "../components/button/IconButton";
import Checkbox from "../components/inputs/Checkbox";
import FilterInput from "../components/inputs/FilterInput";
import FirmwarePopup from "../components/popups/FirmwarePopup";
import FirmwareRolloutPopup from "../components/popups/FirmwareRolloutPopup";
import {
  AllFirmwareQuery,
  AllFirmwareQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
} from "../graphql/graphqlTypes";
import { GET_ALL_FIRMWARE, GET_USER } from "../graphql/queries";

function Firmware() {
  const [openFilter, setOpenFilter] = useState(false);
  const [openFilterRoll, setOpenFilterRoll] = useState(false);
  const firmwareFilter = useReactiveVar(firmwareFilterVar);
  const firmwareSort = useReactiveVar(firmwareSortVar);
  const firmwareFilterRollout = useReactiveVar(firmwareFilterRolloutVar);
  const firmwareRolloutSort = useReactiveVar(firmwareRolloutSortVar);
  //Refetching data after update
  const { refetch: allFirmwareRefetch } = useQuery<AllFirmwareQuery, AllFirmwareQueryVariables>(GET_ALL_FIRMWARE, {
    fetchPolicy: "network-only",
    variables: {
      types: Object.keys(firmwareFilter).filter((key) => {
        return firmwareFilter[key];
      }),
      sort: firmwareSort.sort,
      order: firmwareSort.order,
    },
  });
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (!openFilter && !firmwareFilter.critical && !firmwareFilter.important && !firmwareFilter.normal) {
      firmwareFilterVar({ normal: true, important: true, critical: true });
    }
  }, [openFilter]);
  useEffect(() => {
    if (
      !openFilterRoll &&
      !firmwareFilterRollout.Failed &&
      !firmwareFilterRollout.Ongoing &&
      !firmwareFilterRollout.Unknown
    ) {
      firmwareFilterRolloutVar({ Unknown: true, Ongoing: true, Failed: true });
    }
  }, [openFilterRoll]);
  if (!userData?.user?.permissions) return <></>;
  return (
    <div className="w-full h-full bg-gray-100 flex flex-col">
      <Navbar />
      <div className="flex flex-col h-full overflow-y-auto">
        {/* Content  */}
        <div
          className="m-8 rounded-xl border bg-white flex-grow p-8 flex flex-col justify-between"
          style={{ maxHeight: "calc(100vh - 9rem )" }}>
          <div className="flex justify-between mb-4">
            <div>
              <div className="flex flex-row">
                <div className="text-lg font-bold mt-2 mb-2">Firmware</div>
              </div>
              <span className="text-xs text-black text-opacity-60" style={{ letterSpacing: "0.02rem" }}>
                This is a list of uploaded firmwares
              </span>
            </div>
            <div className="flex flex-row">
              {userData.user.permissions.includes("ViewFirmware") && (
                <div>
                  <IconButton label={"Filter"} Icon={IconFilter} onClick={() => setOpenFilter(!openFilter)} />
                  {openFilter && (
                    <FilterInput
                      open={openFilter}
                      setOpen={setOpenFilter}
                      childe={
                        <div>
                          <div className="text-md font-bold   mb-2">Types</div>
                          <div>
                            <Checkbox
                              id={"checkbox_All"}
                              label={"All"}
                              checked={firmwareFilter.normal && firmwareFilter.important && firmwareFilter.critical}
                              onChange={(e) => firmwareFilterVar({ normal: e, important: e, critical: e })}
                            />
                          </div>
                          <div>
                            <Checkbox
                              id={"checkbox_Normal"}
                              label={"Normal"}
                              checked={firmwareFilter.normal}
                              onChange={(e) => firmwareFilterVar({ ...firmwareFilter, normal: e })}
                            />
                          </div>
                          <div>
                            <Checkbox
                              id={"checkbox_Important"}
                              label={"Important"}
                              checked={firmwareFilter.important}
                              onChange={(e) => firmwareFilterVar({ ...firmwareFilter, important: e })}
                            />
                          </div>
                          <div>
                            <Checkbox
                              id={"checkbox_critical"}
                              label={"Critical"}
                              checked={firmwareFilter.critical}
                              onChange={(e) => firmwareFilterVar({ ...firmwareFilter, critical: e })}
                            />
                          </div>
                        </div>
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="my-6 overflow-auto">
            <Table
              headers={["Name", "Description", "Type", "Uploaded at", ""]}
              Row={<TableRowFirmware />}
              sort={(name) => {
                if (firmwareSort.sort === name) {
                  firmwareSortVar({ ...firmwareSort, order: !firmwareSort.order });
                } else {
                  firmwareSortVar({ ...firmwareSort, sort: name });
                }
              }}
              activeSort={firmwareSort.sort}
              order={firmwareSort.order}
            />
          </div>
          {userData.user.permissions.includes("CreateFirmware") && (
            <div>
              <Button
                label={"New Firmware"}
                disable={false}
                onClick={() => {
                  showPopupVar({
                    chiled: <FirmwarePopup refetch={allFirmwareRefetch} />,
                  });
                }}
              />
            </div>
          )}
        </div>
        <div
          className="m-8 rounded-xl border bg-white flex-grow p-8 flex flex-col justify-between max-h-full"
          style={{ maxHeight: "calc(100vh - 9rem )" }}>
          <div className="flex justify-between mb-4">
            <div>
              <div className="flex flex-row">
                <div className="text-lg font-bold mt-2 mb-2">Firmware rollouts</div>
              </div>
              <span className="text-xs text-black text-opacity-60" style={{ letterSpacing: "0.02rem" }}>
                This is a list of firmware rollouts
              </span>
            </div>
            <div className="flex flex-row">
              {userData.user.permissions.includes("ViewFirmware") && (
                <div>
                  <IconButton label={"Filter"} Icon={IconFilter} onClick={() => setOpenFilterRoll(!openFilterRoll)} />
                  {openFilterRoll && (
                    <FilterInput
                      open={openFilterRoll}
                      setOpen={setOpenFilterRoll}
                      childe={
                        <div>
                          <div className="text-md font-bold   mb-2">Status</div>
                          <div>
                            <Checkbox
                              id={"checkbox_All"}
                              label={"All"}
                              checked={
                                firmwareFilterRollout.Unknown &&
                                firmwareFilterRollout.Ongoing &&
                                firmwareFilterRollout.Failed
                              }
                              onChange={(e) => firmwareFilterRolloutVar({ Unknown: e, Ongoing: e, Failed: e })}
                            />
                          </div>
                          <div>
                            <Checkbox
                              id={"checkbox_Unknown"}
                              label={"Unknown"}
                              checked={firmwareFilterRollout.Unknown}
                              onChange={(e) => firmwareFilterRolloutVar({ ...firmwareFilterRollout, Unknown: e })}
                            />
                          </div>
                          <div>
                            <Checkbox
                              id={"checkbox_Ongoing"}
                              label={"Ongoing"}
                              checked={firmwareFilterRollout.Ongoing}
                              onChange={(e) => firmwareFilterRolloutVar({ ...firmwareFilterRollout, Ongoing: e })}
                            />
                          </div>
                          <div>
                            <Checkbox
                              id={"checkbox_Failed"}
                              label={"Failed"}
                              checked={firmwareFilterRollout.Failed}
                              onChange={(e) => firmwareFilterRolloutVar({ ...firmwareFilterRollout, Failed: e })}
                            />
                          </div>
                        </div>
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="my-6 overflow-auto">
            <Table
              headers={["Firmware", "Group", "Installs", "Status", "Rolled out at", ""]}
              Row={<TableRowFirmreRollouts />}
              sort={(name) => {
                if (name !== "Installs") {
                  if (firmwareRolloutSort.sort === name) {
                    firmwareRolloutSortVar({ ...firmwareRolloutSort, order: !firmwareRolloutSort.order });
                  } else {
                    firmwareRolloutSortVar({ ...firmwareRolloutSort, sort: name });
                  }
                }
              }}
              activeSort={firmwareRolloutSort.sort}
              order={firmwareRolloutSort.order}
            />
          </div>
          {userData.user.permissions.includes("CreateFirmware") && (
            <div>
              <Button
                label={"New Firmware rollout"}
                disable={false}
                onClick={() => {
                  showPopupVar({
                    chiled: <FirmwareRolloutPopup />,
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Firmware;
