type SpinnerProps = {
  size?: string;
  mRight?: string;
  mTop?: string;
  opacity?: number;
};

export default function Spinner(props: SpinnerProps) {
  return (
    <svg
      className="animate-spin"
      style={{
        height: props.size ? props.size : "2rem",
        width: props.size ? props.size : "2rem",
        marginRight: props.mRight ? props.mRight : 0,
        marginTop: props.mTop ? props.mTop : 0,
      }}
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      viewBox="0 0 24 24">
      <path
        className="opacity-10 login-spinner"
        style={{ opacity: props.opacity }}
        fill="black"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  );
}
