import React from "react";
import { IoIosArrowUp as IconBack } from "react-icons/io";
import Checkbox from "../inputs/Checkbox";
type Props = {
  label: string;
  hasChildren: boolean;
  checked: boolean;
  open: boolean;
  onClick: Function;
  changeOpen: Function;
  disabled: boolean;
};

function CheckboxHierarkiListRow(props: Props) {
  const { label, open, hasChildren, onClick, checked, changeOpen, disabled } = props;
  return (
    <div
      className={`flex flex-row w-full justify-between cursor-pointer items-center h-8 rounded-lg bg-transparent hover:bg-gray-100 ${
        !!disabled && "opacity-50"
      }`}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}>
      <Checkbox
        id={""}
        label={label}
        checked={checked}
        onChange={onClick}
        disable={!!disabled}
        textStyle={" font-bold"}
      />
      <button
        className="flex justify-end shrink-0 items-center pr-2 h-10 w-10 text-gray-900 outline-none focus:outline-none "
        onClick={(e) => {
          e.stopPropagation();
          changeOpen();
        }}>
        {hasChildren && (
          <IconBack
            style={{ fill: "rgb(17 24 39)" }}
            className={`h-4 w-4 transform transition-all ${open ? "" : "-rotate-180"} `}
          />
        )}
      </button>
    </div>
  );
}

export default CheckboxHierarkiListRow;
