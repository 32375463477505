import { Division } from "./graphql/graphqlTypes";

export function findChildeDivision(div: Division) {
  if (!div) return [];
  if (!div?.divisions || div.divisions.length < 1) {
    return [div];
  }
  let b = [div];
  div.divisions.forEach((element) => {
    b.push(...findChildeDivision(element));
  });
  return b;
}

export function findParentDivisions(div: Division) {
  let parentDivisions = [div];
  let currentDivision = div;
  while (true) {
    if (currentDivision.parentDivision) {
      parentDivisions.unshift(currentDivision.parentDivision);
      currentDivision = currentDivision.parentDivision;
    } else {
      break;
    }
  }
  return parentDivisions;
}

export const allDiagnosticsEventTypes = [
  "Event",
  "FirmwareUpdate",
  "InsideReading",
  "Reading",
  "Error",
  "Setup",
  "SetAirQualityIndex",
  "StartListening",
  "StopListening",
  "SetDetectLocationEnabled",
  "DeviceConnect",
  "DeviceConnected",
  "DeviceDisconnect",
  "DeviceDisconnected",
  "DeviceReconnect",
  "DeviceConnectError",
  "SetPower",
  "SetSensors",
  "SetFanSpeed",
  "FilterChanged",
  "PoweredOn",
  "PoweredOff",
  "SensorsOn",
  "SensorsOff",
  "FanSpeedChanged",
  "FilterRuntimeChanged",
  "BatteryLevelChanged",
  "DeviceErrorsChanged",
  "Location",
  "ParticleSensor",
  "TemperatureSensor",
  "HumiditySensor",
  "initial",
  "spot",
  "inside",
];

export const allRoles = ["SuperAdmin", "UserAdmin", "DeviceAdmin", "FirmwareAdmin", "DiagnosticViewer"];
