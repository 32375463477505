import React from "react";

type Props = {
  label: string;
  type: "Gray" | "Green" | "Yellow" | "Red" | "Blue";
  tooltip?: string;
};

function Tag(props: Props) {
  const { label, type, tooltip } = props;
  let style = "text-gray-700 bg-gray-200";
  if (type === "Green") {
    style = "text-gray-700 bg-green-200";
  } else if (type === "Yellow") {
    style = "text-gray-700 bg-yellow-200";
  } else if (type === "Red") {
    style = "text-gray-700 bg-red-200";
  } else if (type === "Blue") {
    style = "text-gray-700 bg-blue-200";
  }
  return (
    <div
      title={tooltip}
      className={
        "h-5 justify-center cursor-pointer flex items-center m-1 font-medium px-4 rounded-full border-1  tracking-wider text-xs  capitalize whitespace-nowrap overflow-hidden text-ellipsis  " +
        style
      }>
      <div
        className={" whitespace-nowrap overflow-hidden text-ellipsis"}
        style={{
          maxWidth: "136px",
        }}>
        {label}
      </div>
    </div>
  );
}

export default Tag;
