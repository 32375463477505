import React, { MouseEventHandler } from "react";
import { IconType } from "react-icons";

type Props = {
  label: string;
  Icon?: IconType;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

function IconButton(props: Props) {
  const { label, onClick, Icon, disabled } = props;
  return (
    <button
      className="flex flex-row justify-center items-center border py-2 px-3 m-2 rounded-lg text-xs text-center font-bold
       bg-white text-black cursor-pointer hover:bg-gray-100 
       transform transition-all active:scale-95 disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-auto disabled:active:scale-100 "
      style={{ letterSpacing: "0.05rem" }}
      disabled={disabled}
      onClick={onClick}>
      {Icon && <Icon className="h-4 w-4 mr-2" />}
      {label}
    </button>
  );
}

export default IconButton;
