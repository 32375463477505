import { ReactComponent as BublLogoWhite } from "../../assets/svg/bubl_logo_white.svg";
import BublPulse from "../../assets/images/bubl_pulse.png";
import { MdOutlineLogout } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { GetUserQuery, GetUserQueryVariables } from "../../graphql/graphqlTypes";
import { GET_USER } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import NavbarItem from "./NavbarItem";
import { setActive } from "../../cache";

function Navbar() {
  let history = useHistory();
  const [hidde, setHidde] = useState(true);
  const { data: userData, loading: userDataLoading } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  if (!userData?.user?.permissions) return <></>;
  return (
    <div className="relative bg-bubl-green w-full flex justify-between items-center py-6 px-12">
      <div className="flex items-center" style={{ letterSpacing: "0.05em" }}>
        <BublLogoWhite className="h-5 mr-6" />
        {(userData.user.permissions.includes("CreateAccount") ||
          userData.user.permissions.includes("EditAccount") ||
          userData.user.permissions.includes("DeleteAccount")) && (
          <NavbarItem label={"Dashboard"} active={history.location.pathname === "/"} implemented={false} />
        )}
        {(userData.user.permissions.includes("ViewVisualization") ||
          userData.user.permissions.includes("CreateVisualization") ||
          userData.user.permissions.includes("EditVisualizationt") ||
          userData.user.permissions.includes("DeleteVisualization")) &&
          hidde && <NavbarItem label={"AQI"} active={history.location.pathname.includes("/aqi")} implemented={false} />}

        {(userData.user.permissions.includes("ViewDevice") ||
          userData.user.permissions.includes("ViewFirmware") ||
          userData.user.permissions.includes("ViewProduct") ||
          userData.user.permissions.includes("ViewDiagnostic")) && (
          <div className="relative group flex">
            <NavbarItem label={"IOT"} active={history.location.pathname.includes("/iot")} implemented={true} />
            {/* Dropdown navbar */}
            <div
              id="dropdownNavbar"
              className=" absolute z-50 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-36 group-hover:block top-5">
              <ul className="py-1 text-sm text-gray-700">
                {userData.user.permissions.includes("ViewDevice") && (
                  <li>
                    <a
                      href="/iot/devices"
                      className="block px-4 py-2 hover:bg-gray-100 focus:text-bubl-green"
                      style={{ fontWeight: history.location.pathname.includes("/devices") ? "bolder" : "auto" }}>
                      Devices
                    </a>
                  </li>
                )}
                {userData.user.permissions.includes("ViewFirmware") && (
                  <li>
                    <a
                      href="/iot/firmware"
                      className="block px-4 py-2 hover:bg-gray-100  focus:text-bubl-green"
                      style={{ fontWeight: history.location.pathname.includes("/firmware") ? "bolder" : "auto" }}>
                      Firmware
                    </a>
                  </li>
                )}
                {hidde && userData.user.permissions.includes("ViewProduct") && (
                  <li>
                    <a
                      // href="/iot/products"
                      className="block px-4 py-2  text-gray-400 " //hover:bg-gray-100  focus:text-bubl-green"
                      style={{ fontWeight: history.location.pathname.includes("/products") ? "bolder" : "auto" }}>
                      Products
                    </a>
                  </li>
                )}
                {userData.user.permissions.includes("ViewDiagnostic") && (
                  <li>
                    <a
                      href="/iot/diagnostic"
                      className="block px-4 py-2 hover:bg-gray-100  focus:text-bubl-green"
                      style={{ fontWeight: history.location.pathname.includes("/diagnostic") ? "bolder" : "auto" }}>
                      Diagnostic
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
        {(userData.user.permissions.includes("CreateAccount") ||
          userData.user.permissions.includes("EditAccount") ||
          userData.user.permissions.includes("DeleteAccount")) &&
          hidde && (
            <NavbarItem label={"Sources"} active={history.location.pathname.includes("/sources")} implemented={false} />
          )}

        {(userData.user.permissions.includes("CreateAccount") ||
          userData.user.permissions.includes("EditAccount") ||
          userData.user.permissions.includes("DeleteAccount")) &&
          hidde && (
            <NavbarItem label={"API Access"} active={history.location.pathname.includes("/api")} implemented={false} />
          )}
        {(userData.user.permissions.includes("CreateUser") ||
          userData.user.permissions.includes("EditUser") ||
          userData.user.permissions.includes("DeleteUser")) && (
          <NavbarItem
            label={"Admin"}
            href={"/admin"}
            active={history.location.pathname.includes("/admin")}
            implemented={true}
          />
        )}
      </div>
      <div className="flex items-center z-10">
        <FaUserCircle className="text-white text-xl" />
        <div className="font-bold text-white pr-6 pl-2 text-sm">
          {userData.user.name ? userData.user.name : "A Bubl name"}
        </div>
        <div>
          <MdOutlineLogout
            className="text-white text-xl cursor-pointer"
            onClick={() => {
              setActive(null);
              localStorage.setItem("jwt-bubl-cloud", "");
              history.push("/login");
            }}
          />
        </div>
      </div>
      <div
        className="absolute hidden lg:block overflow-hidden"
        style={{
          width: 312,
          height: "100%",
          right: 116,
          zIndex: 0,
        }}>
        <img
          className="w-full -mt-32"
          src={BublPulse}
          style={{
            transform: "rotate(20deg)",
          }}
        />
      </div>
    </div>
  );
}

export default Navbar;
