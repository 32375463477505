import React from "react";

type Props = {
  id: string;
  label: string;
  checked: boolean;
  onChange: Function;
  disable?: boolean;
  textStyle?: string;
};

function Checkbox(props: Props) {
  const { id, label, checked, onChange, disable, textStyle } = props;
  return (
    <div className="w-full whitespace-nowrap overflow-hidden  my-0.5">
      <input
        type="checkbox"
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        onClick={(e) => e.stopPropagation()}
        id={id}
        checked={checked}
        disabled={disable}
        className="h-4 w-4 mx-2 align-middle appearance-none cursor-pointer shrink-0 float-left"
        name={id}
      />
      <div className={"text-black text-xs whitespace-nowrap overflow-hidden text-ellipsis " + textStyle}>{label}</div>
    </div>
  );
}

export default Checkbox;
