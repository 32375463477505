import { useEffect, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  CreateAccountMutation,
  CreateAccountMutationVariables,
  CreateDivisionMutation,
  CreateDivisionMutationVariables,
  Division,
  GetDivisionsQuery,
  GetDivisionsQueryVariables,
} from "../../graphql/graphqlTypes";
import { CREATE_ACCOUNT, CREATE_DIVISION } from "../../graphql/mutations";
import { activeInfo, showPopupVar } from "../../cache";
import Button from "../button/Button";
import TextInput from "../inputs/TextInput";
import CheckboxHierarkiList from "../checkboxHierarkeList/CheckboxHierarkiList";
import { GET_DIVISIONS } from "../../graphql/queries";

type PropsPopup = {
  type: "ACCOUNT" | "GROUP";
  refetch: Function;
};
function AddDivisionPopup({ type, refetch }: PropsPopup) {
  const [name, setName] = useState("");
  const active = useReactiveVar(activeInfo);
  const showPopup = useReactiveVar(showPopupVar);
  const [selectedGroup, setSelectedGroup] = useState<Division>(active?.division);

  const { data: divisionData } = useQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(GET_DIVISIONS);
  const [createAccount] = useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CREATE_ACCOUNT);
  const [createDivision] = useMutation<CreateDivisionMutation, CreateDivisionMutationVariables>(CREATE_DIVISION);
  async function handelSave() {
    if (type === "ACCOUNT" && name) {
      await createAccount({
        variables: { name: name },
      });
      showPopupVar(null);
      refetch();
    } else if (type === "GROUP" && name && active.division.id) {
      const { data } = await createDivision({
        variables: { name: name, parentDivisionId: selectedGroup.id },
      });
      showPopupVar(null);
      refetch();
    }
  }

  useEffect(() => {
    showPopupVar({ ...showPopup, save: handelSave });
  }, [name, active]);
  return (
    <div className="flex flex-col justify-between" style={{ minWidth: "290px" }}>
      <div className="text-lg font-bold mt-2 mb-2">
        {type === "ACCOUNT" ? "Add account" : "Add Group to " + active.division.name}
      </div>
      <TextInput
        id={"name"}
        label={type === "ACCOUNT" ? "Account name" : "Group name"}
        value={name}
        onChange={(value) => {
          setName(value);
        }}
        type={"text"}
      />
      {type === "GROUP" && (
        <>
          <div className="mb-6">
            <div className=" items-start block mb-2 text-sm font-bold text-gray-900 ">Choose a Group</div>
            <div className=" rounded-lg overflow-x-auto h-40  ">
              {divisionData?.divisions?.map((division, i) => {
                return (
                  <CheckboxHierarkiList
                    key={(division.id, i)}
                    activeDivisions={[selectedGroup?.id]}
                    list={division}
                    step={0}
                    onClick={(div: Division) => {
                      if (div.id !== selectedGroup?.id) setSelectedGroup(div);
                      else setSelectedGroup(null);
                      showPopupVar({ ...showPopup, outsideSafety: true });
                    }}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}

      <div className="-ml-2">
        <Button
          label={type === "ACCOUNT" ? "Add account" : "Add Group"}
          disable={!name || (type === "GROUP" ? !active.division : false)}
          onClick={() => {
            handelSave();
          }}
        />
      </div>
    </div>
  );
}

export default AddDivisionPopup;
