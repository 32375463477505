import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetRegisterUserQuery,
  GetRegisterUserQueryVariables,
  RegisterMutation,
  RegisterMutationVariables,
} from "../graphql/graphqlTypes";
import { REGISTER } from "../graphql/mutations";
import { GET_REGISTER_USER } from "../graphql/queries";
import TextInput from "../components/inputs/TextInput";
import { ReactComponent as BublLogo } from "../assets/svg/bubl_logo.svg";
import BublPulse from "../assets/images/bubl_pulse.png";
import Checkbox from "../components/inputs/Checkbox";
import Button from "../components/button/Button";

function Register() {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkedBoxValue, setCheckedBoxValue] = useState(false);
  const [complete, setComplete] = useState(false);
  const [responseError, setResponseError] = useState("");

  const [register] = useMutation<RegisterMutation, RegisterMutationVariables>(REGISTER);

  let token: string | null = null;
  token = new URLSearchParams(useLocation().search).get("token");
  if (!token) {
    history.push("/login");
  }
  const { data: registerUserData, error: registerUserDataError } = useQuery<
    GetRegisterUserQuery,
    GetRegisterUserQueryVariables
  >(GET_REGISTER_USER, {
    fetchPolicy: "network-only",
    variables: { registerToken: token },
  });
  useEffect(() => {
    if (registerUserData && registerUserData.user) {
      setEmail(registerUserData.user.email);
    }
    if (registerUserDataError || (registerUserData && !registerUserData.user)) {
      history.push("/login");
    }
  }, [registerUserData, registerUserDataError]);

  useEffect(() => {
    if (password && checkedBoxValue) {
      setComplete(true);
    } else {
      setComplete(false);
    }
  }, [password, checkedBoxValue]);

  async function handelRegisterUserData() {
    if (complete) {
      try {
        const { data } = await register({
          variables: { password: password, registerToken: token },
        });
        if (data.register.success) {
          setTimeout(() => {
            history.push("/login");
          }, 1000);
        } else if (data && data.register.error.msg) {
          setResponseError(data.register.error.msg);
        } else {
          setResponseError("Unknown error");
        }
      } catch (error) {
        setResponseError("Unknown error");
      }
    }
  }
  return (
    <div className="h-full w-full flex flex-col justify-center items-center bg-bubl-green relative">
      <div
        className="bg-white rounded-full z-50"
        style={{ height: 600, width: 600, paddingLeft: 140, paddingRight: 140, paddingTop: 110, paddingBottom: 110 }}>
        <div className="w-full flex justify-center">
          <BublLogo className="w-32" />
        </div>
        <div className="text-lg font-bold mt-6 mb-6">Register</div>
        <TextInput id={"email"} disable={true} label="Your email" type="email" value={email} onChange={(value) => {}} />
        <TextInput
          id={"password"}
          label="Password"
          type="password"
          value={password}
          onChange={(value) => {
            setPassword(value);
          }}
        />
        <Checkbox
          id={"policy"}
          label={"I agree to Integrity policy and Cookie policy"}
          checked={checkedBoxValue}
          onChange={(e) => {
            setCheckedBoxValue(e);
          }}
        />
        <div className="flex justify-center">
          <Button
            label={"Register"}
            disable={!complete}
            onClick={() => {
              handelRegisterUserData();
            }}
          />
        </div>
        {responseError && <div style={{ padding: 20 }}>{responseError}</div>}
      </div>
      <img
        className="absolute"
        style={{ width: 1200, height: 1100, bottom: -340, right: -200, transform: "rotate(10deg)", zIndex: 0 }}
        src={BublPulse}></img>
    </div>
  );
}

export default Register;
