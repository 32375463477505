import React, { useState } from "react";
import CheckboxHierarkiListRow from "./CheckboxHierarkiListRow";
import { Account, Division } from "../../graphql/graphqlTypes";

type Props = {
  list: Division;
  activeDivisions: Array<string>;
  step: number;
  onClick: Function;
  account?: Account;
};
// Recursive list
function CheckboxHierarkeList(props: Props) {
  const { list, activeDivisions, step, onClick, account } = props;
  const [open, setOpen] = useState(true);
  return (
    <div className={" mt-0.5 ml-2"}>
      {/* Show the row */}
      <CheckboxHierarkiListRow
        label={list.name}
        checked={activeDivisions?.includes(list.id)}
        disabled={list.disabled}
        open={open}
        hasChildren={list.divisions && list.divisions.length > 0}
        onClick={() => {
          onClick(list, account);
        }}
        changeOpen={() => {
          setOpen(!open);
        }}
      />
      {/* Go down in hirerkin to the division children */}
      {list?.divisions &&
        list.divisions.length > 0 &&
        open &&
        list.divisions.map((element, i) => {
          return (
            <CheckboxHierarkeList
              activeDivisions={activeDivisions}
              key={i}
              list={element}
              step={step + 1}
              onClick={onClick}
              account={account}
            />
          );
        })}
    </div>
  );
}

export default CheckboxHierarkeList;
