// Apollo Client cache storing "Reactive variables" https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/#storing-local-state-in-reactive-variables

import { makeVar } from "@apollo/client";
import { Account, Division } from "./graphql/graphqlTypes";
import { allDiagnosticsEventTypes, allRoles } from "./utils";

export const getActive = () => {
  if (localStorage.getItem("bubl-active")) {
    return JSON.parse(localStorage.getItem("bubl-active"));
  }
};
export const setActive = (value) => {
  localStorage.setItem("bubl-active", JSON.stringify(value));
  // Send "storage" event manually, for listener to trigger
  // See: https://stackoverflow.com/questions/35865481/storage-event-not-firing
  window.dispatchEvent(new Event("storage"));
};

export const showConfirmDialogVar = makeVar<null | {
  title: string;
  message: string;
  confirmText: string;
  saveText?: string;
  onConfirm: Function;
  onSave?: Function;
  onCancel?: Function;
}>(null);
export const showPopupVar = makeVar<null | {
  chiled: JSX.Element;
  save?: Function;
  outsideSafety?: Boolean;
}>(null);
export const activeInfo = makeVar<null | {
  account: Account;
  division: Division;
}>(getActive());

export const firmwareFilterVar = makeVar<null | {
  normal: boolean;
  important: boolean;
  critical: boolean;
}>({
  normal: true,
  important: true,
  critical: true,
});
export const firmwareSortVar = makeVar<null | {
  sort: string;
  order: boolean;
}>({
  sort: "Uploaded at",
  order: false,
});

export const firmwareFilterRolloutVar = makeVar<null | {
  Unknown: boolean;
  Ongoing: boolean;
  Failed: boolean;
}>({
  Unknown: true,
  Ongoing: true,
  Failed: true,
});
export const firmwareRolloutSortVar = makeVar<null | {
  sort: string;
  order: boolean;
}>({
  sort: "Rolled out at",
  order: false,
});
export const diagnosticFilterVar = makeVar<null | {
  searchText: string;
  sort: string;
  order: boolean;
  types: string[];
  take: number;
  skip: number;
}>({
  searchText: "",
  sort: "Logged at",
  order: false,
  types: allDiagnosticsEventTypes,
  take: 100,
  skip: 0,
});
export const deviceFilterVar = makeVar<null | {
  searchText: string;
  sort: string;
  order: boolean;
}>({
  searchText: "",
  sort: "Last activity",
  order: false,
});
export const adminFilterVar = makeVar<null | {
  roles: string[];
  sort: string;
  order: boolean;
}>({
  roles: allRoles,
  sort: "Name",
  order: true,
});
export const userRolesFilterVar = makeVar<null | {
  sort: string;
  order: boolean;
}>({
  sort: "Name",
  order: true,
});
