import { useReactiveVar } from "@apollo/client";
import { showPopupVar } from "../../cache";
import { Diagnostic } from "../../graphql/graphqlTypes";
import Tag from "../Tag";
import Button from "../button/Button";

type PropsPopup = {
  diagnosticError: Diagnostic;
};
function DiagnosticPopup({ diagnosticError }: PropsPopup) {
  const showPopup = useReactiveVar(showPopupVar);
  function parsJson(str: string) {
    try {
      return JSON.stringify(JSON.parse(str), undefined, 2);
    } catch (error) {
      console.log("Problem convering to Json object");
    }
    return "";
  }
  return (
    <div className="flex flex-col justify-between px-2" style={{ width: "450px" }}>
      <div className="text-lg font-bold mt-2 mb-2 ">Diagnostic Info</div>
      <div className="flex flex-row justify-between">
        <div className="mb-6">
          <label className="block mb-2 text-sm font-bold text-gray-900 ">Logges At</label>
          {diagnosticError?.date
            ? new Date(diagnosticError.date).toLocaleString("sv-SE", {
                dateStyle: "short",
                timeStyle: "medium",
              })
            : ""}
        </div>
        <div className="mb-6 mr-6">
          <label className="block mb-2 text-sm font-bold text-gray-900 ">Type</label>
          <div>{diagnosticError?.type && <Tag label={diagnosticError.type} type={"Gray"}></Tag>}</div>
          <div>{diagnosticError?.messageType && <Tag label={diagnosticError.messageType} type={"Gray"}></Tag>}</div>
        </div>
      </div>
      <div className="mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-900 ">Mac address</label>
        <pre>{diagnosticError?.macAddress}</pre>
      </div>
      <div className="mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-900 ">Install ID</label>
        <pre>{diagnosticError?.installRelationId}</pre>
      </div>
      <div className="mb-6 first-letter">
        <label className="block mb-2 text-sm font-bold text-gray-900 ">Message</label>
        <pre className="whitespace-pre-wrap">
          <code>
            {parsJson(diagnosticError?.message) ? parsJson(diagnosticError?.message) : diagnosticError?.message}
          </code>
        </pre>
      </div>
      <div className="-ml-4">
        <Button
          label={"Close"}
          disable={false}
          onClick={() => {
            showPopupVar(null);
          }}
        />
      </div>
    </div>
  );
}

export default DiagnosticPopup;
