import { useEffect, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  AccountUsersQuery,
  AccountUsersQueryVariables,
  GetRolesQuery,
  GetRolesQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  Role,
  UpdateDivisionUserRolesMutation,
  UpdateDivisionUserRolesMutationVariables,
  User,
} from "../../graphql/graphqlTypes";
import { UPDATE_DIVISION_USER_ROLES } from "../../graphql/mutations";
import { activeInfo, showPopupVar } from "../../cache";
import Button from "../button/Button";
import SelectInput from "../inputs/SelectInput";
import { GET_ACCOUNT_USER, GET_ROLES, GET_USER } from "../../graphql/queries";
import { MdInfo as IconInfo } from "react-icons/md";
import Tooltip from "../inputs/Tooltip";

type PropsPopup = {
  refetch: Function;
  initUser?: User;
};
function UserRolePopup({ refetch, initUser }: PropsPopup) {
  const [update, setUpdate] = useState(false);
  const [user, setUser] = useState(initUser ? initUser : null);
  const [selectedRoles, setSelectedRoles] = useState(initUser?.roles ? initUser.roles : []);
  const activeRow = useReactiveVar(activeInfo);
  const showPopup = useReactiveVar(showPopupVar);

  const { data: accountUserRolesData } = useQuery<AccountUsersQuery, AccountUsersQueryVariables>(GET_ACCOUNT_USER, {
    variables: { accountId: activeRow?.account?.id },
    skip: !activeRow?.account?.id,
    fetchPolicy: "no-cache",
  });
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  const { data: allRolesData } = useQuery<GetRolesQuery, GetRolesQueryVariables>(GET_ROLES);
  const [updateDivisionUserRoles] = useMutation<
    UpdateDivisionUserRolesMutation,
    UpdateDivisionUserRolesMutationVariables
  >(UPDATE_DIVISION_USER_ROLES);
  async function handelSave() {
    // Update roles
    if (user && selectedRoles && selectedRoles.length > 0) {
      let divRoles = [];
      user.roles.forEach((role) => {
        if (role.divisions.some((divison) => divison.id === activeRow.division.id)) {
          divRoles.push(role);
        }
      });
      let addedRoles = selectedRoles.filter((role) => !divRoles.includes(role));
      let removedRoles = divRoles.filter((role) => !selectedRoles.includes(role));
      await updateDivisionUserRoles({
        variables: {
          userId: user.id,
          divisionId: activeRow.division.id,
          addedRoleIds: addedRoles.map((e) => e.id),
          removedRoleIds: removedRoles.map((e) => e.id),
        },
      });
    }
    refetch();
    showPopupVar(null);
  }
  useEffect(() => {
    showPopupVar({ ...showPopup, save: handelSave });
  }, [user, selectedRoles]);
  return (
    <div className="flex flex-col justify-between px-4" style={{ width: "500px" }}>
      <div className="text-lg font-bold mt-2 mb-2 ">{initUser ? "Edit UserRole" : "New UserRole"}</div>
      <div className="my-6">
        <div className="flex flex-row">
          <label htmlFor="default-input" className="block mb-2 text-sm font-bold text-gray-900 mr-2">
            User
          </label>
        </div>
        {accountUserRolesData?.accountUsers && userData && (
          <SelectInput
            multiple={false}
            {...(initUser && { initValue: { id: initUser.id, name: initUser.name } })}
            items={accountUserRolesData.accountUsers.filter((user) => {
              return user.id !== userData.user.id;
            })}
            disabled={!!initUser}
            onChange={(e: User) => {
              setUser(e[0]);
              let activeRoles = [];
              e[0].roles.forEach((role: Role) => {
                if (role.divisions.some((divison) => divison.id === activeRow.division.id)) activeRoles.push(role);
              });
              setSelectedRoles(activeRoles);
              setUpdate(!update);
            }}
          />
        )}
      </div>
      <div className="mb-8">
        <div className="flex flex-row">
          <label htmlFor="default-input" className="block mb-2 text-sm font-bold text-gray-900 mr-2">
            Roles
          </label>
          <Tooltip
            label={"Add roles to the user for access control and permissions in selected groups"}
            left={"22px"}
            top={"0px"}
            bottom={"0px"}
            childern={<IconInfo className="h-5 w-5 mr-2 cursor-pointer" />}
          />
        </div>
        {allRolesData?.roles && (
          <>
            <SelectInput
              multiple={true}
              items={allRolesData.roles.filter((role) => {
                return role.name !== "SuperAdmin" && role.name !== "UserAdmin";
              })}
              initValues={selectedRoles}
              update={update}
              onChange={(e) => {
                setSelectedRoles(e);
              }}
            />
          </>
        )}
      </div>
      <div className="-ml-4">
        <Button
          label={!initUser ? "Create" : "Update"}
          disable={!(user && selectedRoles.length > 0)}
          onClick={() => {
            handelSave();
          }}
        />
      </div>
    </div>
  );
}

export default UserRolePopup;
